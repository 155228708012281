// Core Components
import React from 'react';

// Styles
import './TourBundleTable.css';

// Custom Components
import TourBundleRow from './TourBundleRow';


class TourBundleTable extends React.Component{
    constructor(props){
        super();
    }

    render(){
        return(
            <div className = "tourBundleTableContainer">
                {
                    this.props.bundles.map((bundle, index) => (
                        this.props.embarkment === 1 && !bundle.cayes ? null :
                        this.props.embarkment === 0 && !bundle.cruise ? null :
                        <div key = {index}>
                            <TourBundleRow embarkment={this.props.embarkment}
                                clickBundleFunction = {this.props.clickBundleFunction}
                                bundle={bundle}
                                tours={this.props.tours}/>
                            {/* <div class="tourBundleRowContainer">
                                <h3>{bundle.name}</h3>
                                <div class = "tourBundleRow">
                                    <div class = "tourBundleColumn flexMax">
                                        <h4> Includes </h4>
                                        <div class="padded"><ul>
                                            {bundle.tours.map(tour => (
                                                <li>
                                                    {this.props.tours.find(o => o.name===tour).text}
                                                </li>
                                            ))}
                                        </ul></div>
                                        <GridOptionsShowToggle/>
                                    </div>
                                    <div class = "tourBundleColumn flexMin">
                                        <h4> Price </h4>
                                        <div class="padded-top">
                                            <div>
                                                ${bundle.price} USD Per Person
                                            </div>
                                            <div class="optionsItem">
                                                <Discount/> Save ${bundle.savings} USD per person on this bundle!
                                            </div>
                                        </div>
                                    </div>
                                    <div class = "tourBundleColumn flexMin">
                                        <h4> Available To </h4>
                                        <div class="padded-top">
                                                {bundle.cruise ? 
                                                    <div class="optionsItem"> <span class="optionsItemText">Cruise Visitors</span> <DirectionsBoatFilledIcon fontSize="large"/></div>
                                                : <></>
                                                }
                                                {bundle.cayes ? 
                                                    <div class="optionsItem"> <span class="optionsItemText">Caye Visitors</span> <Sailing fontSize="large"/></div>
                                                : <></>}
                                                <div class="optionsItem"> <span class="optionsItemText"> Visitors Staying in Belize City</span><LocationCity fontSize="large"/></div>
                                        </div>
                                    </div>
                                    <div class = "tourBundleColumn flexMin">
                                        <h4> Logistics </h4>
                                    </div>
                                </div>
                                <ol className="tourBundleItems">{bundle.tours.map(tour =>(
                                    <li className="tourCardBundleItem">
                                        <div className="tourCardBundle" 
                                             style={{backgroundImage: `url(${this.props.tours.find(o=>o.name===tour).cardImage})`}}>
                                            <a className="tourBundleGridLink"
                                               href={this.props.tours.find(o => o.name===tour).url}>
                                                {this.props.tours.find(o => o.name===tour).text}
                                            </a>
                                            <a href = {this.props.tours.find(o => o.name===tour).url} className = "seeTour">View this Tour</a>
                                        </div>
                                    </li>))}
                                </ol>
                            </div> */}
                            {/* <div className="bundleLogoGrid">
                                <div className="bundleLogo label">
                                    <div className = "bundleLogoTextContent"> 
                                    This Bundle is Available for Passengers coming to Belize City via:</div> 
                                </div>
                                {bundle.cayes ? 
                                <div className={this.props.filter === "Caye Visitors" ? "bundleLogo chosen" : "bundleLogo"}>
                                    <div className="bundleLogoItem">Caye Visitors</div>
                                </div>
                                : null}
                                <div className={this.props.filter === "Hotel Visitors" ? "bundleLogo chosen" : "bundleLogo"}>
                                    <div className="bundleLogoItem">Hotel Visitors</div>
                                </div>
                                {bundle.cruise ? 
                                <div className={this.props.filter === "Cruise Passengers" ? "bundleLogo chosen" : "bundleLogo"}>
                                    <div className="bundleLogoItem">Cruise Passengers</div> 
                                </div>
                                : <div className="bundleLogo empty"></div> 
                                }
                            </div>  */}
                        </div> 
                    ))
                }
            </div>
        );
    }
}
export default TourBundleTable; 