import React from 'react';              //Use of class-based react components

import SmallHamburger from './SmallHamburger.js'; 

class DropdownContents extends React.Component {
    constructor(props) {
        super(); 

        this.state = {
            hotelDropdown: false, 
            cruiseDropdown: false 
        }

    }

    render() {
        return (
            <div className={this.props.show ? "dropdownContents show" : "dropdownContents"}>
                {
                    this.props.siteUrls.map(page => (
                        page.id === "desktopLand" ?
                            <div> 
                                <div className={this.props.show ? "dropdownOption show" : "dropdownOption"}
                                    onClick={() => {
                                        this.setState({
                                            hotelDropdown: !this.state.hotelDropdown
                                        });
                                    }}>
                                    <div className="dropdownOptionText">All Tours</div> 
                                    <SmallHamburger toggled={this.state.hotelDropdown}/> 
                                </div> 
                                <div className={this.state.hotelDropdown ? "mobileSubmenuWrapper show" : "mobileSubmenuWrapper"}> 
                                            <a href="/tours/allTours"
                                               className={this.state.hotelDropdown ? "dropdownOption show" : "dropdownOption"}>
                                                Grid View
                                            </a> 
                                            {this.props.tours.map(tour => (
                                                <a href={tour.url}
                                                   className={this.state.hotelDropdown ? "dropdownOption show" : "dropdownOption"}>
                                                        {tour.text}
                                                    </a>))}
                                </div>
                            </div>
                        : page.id === "desktopCruise" ?
                            <div>
                                <div className={this.props.show ? "dropdownOption show" : "dropdownOption"}
                                    onClick={() => {
                                        this.setState({
                                            cruiseDropdown: !this.state.cruiseDropdown
                                        })
                                    }}>
                                    <div className="dropdownOptionText">Norweigan Cruise Tours</div>
                                    <SmallHamburger toggled={this.state.cruiseDropdown}/> 
                                </div> 
                                <div className={this.state.cruiseDropdown ? "mobileSubmenuWrapper show" : "mobileSubmenuWrapper"}>
                                    <a href="/tours/cruiseTours"
                                        className={this.state.cruiseDropdown ? "dropdownOption show" : "dropdownOption"}>
                                        Grid View
                                    </a> 
                                    {
                                        this.props.tours.filter(tour => tour.cruise === true).map(filteredTour => (
                                            <a href={filteredTour.url}
                                               className={this.state.cruiseDropdown ? "dropdownOption show" : "dropdownOption"}>
                                                {filteredTour.text}
                                            </a>
                                        ))
                                    }
                                </div>
                            </div>
                        : page.clickFunction !== null && page.key && page.url ?
                            <div className = {this.props.show ? "dropdownOption show" : "dropdownOption"}
                                onClick = {() => {page.clickFunction()}}>
                                {page.text}
                            </div>
                        : page.key && page.url ?
                        <a className={this.props.show ? "dropdownOption show" : "dropdownOption"}
                           href={page.url}>
                            {page.text}
                        </a>
                        : <></>
                 ))}
            </div>
        );
    }
}
export default DropdownContents;