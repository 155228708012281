//Above imports may be unnecessary...we shall see 
import DropdownArea from './DropdownArea.js'; 

import React from 'react';              //Use of class-based react components
import logoImg from './../images/cave_tubing_mobile.png';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import { LocationCity } from '@material-ui/icons';
import {GiIsland} from 'react-icons/gi'


function MobileNavbar ({fixed, siteUrls, tours, embarkment, embarkmentChange}) {
    return (
        <nav id="mobileNavbar" className={fixed ? "mobileNavbar fixed" : "mobileNavbar"}>
            <DropdownArea siteUrls={siteUrls}
                          tours={tours} />
            <a className="companyLogoContainer"
               href="/">
                <img src = {logoImg} alt = "Enjoy a Belize cave tubing tour with Major Tom and Minor Tom. A tourism guide in Belize since 1992, specializing in cave tubing, zip line, Mayan sites, fishing and snorkeling tours in Belize."/>
            </a>
            {embarkment !== null && embarkmentChange ?
            <div className = "changeEmbarkmentContainer">
                    <span class="changeEmbarkmentLabel">Change Embarkment:</span>
                <div className = "flexed flexMax">
                        <DirectionsBoatFilledIcon className={embarkment === 0 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
                            onClick = {() => {embarkmentChange(0)}}/>
                        <LocationCity className={embarkment === 2 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
                            onClick = {() => {embarkmentChange(2)}}></LocationCity>
                        <GiIsland className={embarkment === 1 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
                            onClick = {() => {embarkmentChange(1)}}/>
                </div>
            </div>
            : <></>
            }
        </nav>
    );
} export default MobileNavbar;

//This is object-oriented so that it can easily manage the state of the dropdowns
// class DesktopNavbar extends React.Component {

//     constructor(props) {
//         super(); 
//         this.props = props;
//     }

//     render() {
//         return (
//             <nav className="mobileNavbar">
//                 <DropdownArea siteUrls={this.props.siteUrls}
//                               tours={this.props.tours} /> 
//                 <a className="companyLogoContainer"
//                    href="/">
//                     <img src = {logoImg} alt = "Enjoy a Belize cave tubing tour with Major Tom and Minor Tom. A tourism guide in Belize since 1992, specializing in cave tubing, zip line, Mayan sites, fishing and snorkeling tours in Belize."/>
//                 </a>
//                 <div className = "changeEmbarkmentContainer">
//                         <span class="changeEmbarkmentLabel">Change Embarkment:</span>
//                     <div className = "flexed flexMax">
//                             <DirectionsBoatFilledIcon className={embarkment === 0 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
//                                 onClick = {() => {embarkmentChange(0)}}/>
//                             <LocationCity className={embarkment === 2 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
//                                 onClick = {() => {embarkmentChange(2)}}></LocationCity>
//                             <GiIsland className={embarkment === 1 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
//                                 onClick = {() => {embarkmentChange(1)}}/>
//                     </div>
//                 </div>
//             </nav>
//         );
//     }
// }

// export default DesktopNavbar;