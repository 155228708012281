//Styles
import './../App.css';
import '../Styles/ThankYou.css';

//Libraries, plugins
import React from 'react';              //Use of class-based react components

const faqElement = <div>It's possible your question may be answered if you check out our <a className="prettyLink" href="/FAQ">FAQ</a> and <a className="prettyLink" href="/safety"> safety guidelines</a></div>; 
const contactElement = <div>If you are concerned that our guides have lost your question, please don't hesitate to email us at info@cave-tubing.net and we will get back to you as soon as we can!</div >;
const thankYouText = [
    "Our guides have received your question and will answer promptly!.",
    "You should receive a confirmation email at the address specified.",
    faqElement,
    contactElement
];

class ThankYouContact extends React.Component {

    constructor(props) {
        super(); 
    }

    render() {
        return (
            <div className="thankYouContainer">
                <div className="containerText">
                    <h1>
                        We Received Your Question!
                    </h1>
                    {thankYouText.map(textRow => (
                        <div className="textRow">{textRow}</div> 
                    ))}
                </div> 
            </div>
        );
    }
}

export default ThankYouContact; 