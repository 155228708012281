import React from 'react';

class TextField extends React.Component {

    constructor(props) {
        super();
        this.placeholder = props.placeholder; 
        this.for = props.for; 
        this.labelText = props.labelText; 
        this.changeFunction = props.changeFunction;
        this.state = {
            value: '', status: false
        }
        this.criteriaFunction = props.criteriaFunction; 
    }


    render() {
        return (
            <div className={this.props.text ? "contactFormField text" : this.props.fullWidth ? "contactFormField maxWidth" : "contactFormField"}>
                <label for={this.for}>{this.labelText}
                    <span class={this.state.status ? "hidden" : "red"}>*</span> 
                </label>
                <input type="text"
                    className={this.state.status ? "formControl green" : "formControl red"} 
                    name = {this.for}
                    id = {this.for} 
                    placeholder={this.placeholder}
                    onChange={(event) => {
                        this.setState({
                            value: event.target.value,
                            status: this.criteriaFunction(event.target.value)
                        });
                        this.changeFunction(event.target.value, this.criteriaFunction(event.target.value));
                    }}
                    required />
            </div> 
        );
    }
}



export default TextField;