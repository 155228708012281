import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

class LogisticsGridItem extends React.Component {

    constructor(props) {
        super();
    }

    render() {
        return (
            <Accordion defaultExpanded = {true} expanded = {true} className="logisticsGridItem accordion noMargin">
                <AccordionSummary>
                    <div className="flexed row">
                        <div>
                            {this.props.title}
                        </div>
                        <div>
                            {this.props.icon}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        {this.props.content.map(bullet => (<li>{bullet}</li>))}
                    </ul>
                </AccordionDetails>
            </Accordion>
        );
    }
}


export default LogisticsGridItem;