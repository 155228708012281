import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import React from 'react';


import './App.css';
import DesktopNavbar from './Components/DesktopNavbar.js';
import Home from './Components/Home.js'; 
import About from './Components/About.js';
import BookNow from './Components/BookNow.js';
import AllTours from './Components/AllTours.js';
import Contact from './Components/Contact.js';
import Covid from './Components/Covid.js'; 
import FAQ from './Components/FAQ.js'; 
import Footer from './Components/Footer.js';
import MobileNavbar from './Components/MobileNavbar.js'
import FacebookIcon from './Components/FacebookIcon.js';
import Safety from './Components/Safety.js'; 
import TourBody from './Components/TourBody.js'; 
//import whatsappIcon from './Components/whatsappIcon.js';
import TripadvisorIcon from './Components/TripadvisorIcon.js';
import ThankYou from './Components/ThankYou.js'; 
import ThankYouContact from './Components/ThankYouContact.js'; 

import lamanaiAlts from './images/lamanai/lamanaiAlts.js'; 
import xunantunichAlts from './images/xunantunich/xunantunichAlts.js'; 
import zipliningAlts from './images/ziplining/zipliningAlts.js'; 
import caveTubingAlts from './images/caveTubing/caveTubingAlts.js'; 
import altunHaAlts from './images/altunHa/altunHaAlts.js'; 


//Begin importing grid images
import zipliningImage from './images/ziplining.jpg';
import caveTubingImage from './images/caveTubing.jpg';
import zooImage from './images/zoo.jpg';
//import altunHaImage from './images/altunHa.jpg'; 
import altunHaImage from './images/altunHa.jpg'; 
import xunantunichImage from './images/xunantunich.jpg'; 
import lamanaiImage from './images/lamanai.jpg';

// Material
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TourIcon from '@mui/icons-material/Tour';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MasksIcon from '@mui/icons-material/Masks';
import EmailIcon from '@mui/icons-material/Email';

function importAll(r) {
    let ret = [];
    let i = 0;
    r.keys().map((item, index) => { ret[item.replace('./', '')] = r(item); ++i });
    ret["numKeys"] = i;
    return ret;
}

const siteUrls = [
    { key: "home", icon: <HomeIcon/>, url: "/", text: "Home", component: Home },
    { key: "about", icon: <InfoIcon/>, url: "/About", text: "About", component: About},
    { key: "booknow", icon: <ConfirmationNumberIcon/>, url: "/BookNow", text: "Book Now", component: BookNow},
    { key: "alltours", icon: <TourIcon/>, url: "#tours", text: "Tours", id: "desktopLand", component: AllTours },
    { key: "bundletours", icon: <TourIcon/>, url: "#tourBundles", text: "Tour Bundles", component: AllTours },
    // { key: "contactus", icon: <CallIcon/>, url: "/Contact", text: "Contact Us", component: Contact },
    { key: "faq", icon: <QuestionAnswerIcon/>, url: "/FAQ", text: "FAQ", component: FAQ },
    // { key: "covid", icon: <MasksIcon/>, url: "/Covid", text: "Covid", component: Covid},
    { icon: <CallIcon/>, text: "5016600495"},
    { icon: <EmailIcon/>, text: "info@cave-tubing.net"}
];

const followUs = [
    { imgComponent: FacebookIcon, url: "http://www.facebook.com/pages/Belize-cave-tubing/252064674785" },
    { imgComponent: TripadvisorIcon, url: "https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html" }
]; 

const lamanaiContent = [
    "Lamanai, on the New River Lagoon. Just an hour up the paved Western Highway is the New River, which flows from the New River Lagoon north to the Bay of Corozal. This river is a wildlife haven and offers the perfect combination tour of ancient Maya site and pleasant riverboat tour with exotic flora and fauna. The boats at the New River Bridge take you for about an hour and a half on this pristine rainforest river for the first leg of your adventure, where you will also pass the famous Mennonite settlement of Shipyard in the area, and other small farms.",
    "Arriving at the Lamanai Maya site on the New River Lagoon, you’ll first be treated to a great Belizean lunch of rice and beans, and stewed chicken, with fruit and a drink, under the palapa at the site, before setting off on your easy rainforest trail through one of the most ancient cities of the world famed Maya civilization, actually called LAMANAYIN, which means “submerged crocodile”. The croc was considered to be an earthly manifestation of the first dark lord of the underworld (the Maya believed NINE dark lords ruled the underworld, called “ZIBALBA”).",
    "Look out for exotic birds and the howler and spider monkeys who inhabit the trees at the site. At the end of your exploration, hop back on board your riverboat and head back to the bridge where you started, and then on board your van, back to Belize City, or wherever it is you started from on this most popular full day tour.",
    "NOTE: Bring along fly repellant, sun block lotion, and you may also want a cap/hat for the day’s activity. Bottled water is served, but you may bring your own as well. Cameras are always welcome. Lunch is always included in this tour. Watch the hot habanero pepper sauce! :-)"
];

// const cahalPechContent = [
//     "The Cahal Pech Maya site sits on the top of a high hill overlooking modern day San Ignacio/Santa Elena, beautiful twin towns of the Western District of Cayo. Dating back to 1,000 B.C, this important ceremonial and trading center of the ancient Maya dominated the area, with the serene Macal River flowing beneath the temples and homes of the people of a bygone civilization.",
//     "Since the Maya could not expand outward, after running out of hill space, they expanded upward, giving this site the most unique collection of rooms atop rooms, corbelled archways, stairways to the stars, and plazas where once the rich and powerful rulers passed their edicts and continued spreading their influence over their people.",
//     "The site also has a well organized display center, where you may view some excellent artifacts, once sacred to the Maya for their important religious ceremonies."
// ]

const xunantunichContent = [
    "Located in the lush, mountainous Cayo district, these are the tallest structures in Belize. ",
    "This impressive Maya ceremonial site is well worth a visit and a climb - from the top the panorama encompasses the thick green Guatemalan Peten District, the Maya Mountains, and a grand view of the entire Cayo District and Belize jungle stretching for miles",
    "After the tour we will enjoy lunch in San Ignacio, an idyllic rest stop on the days journey."
]

const altunHaContent = ["Altun Ha is one of the more popular and recognized Mayan sites of Belize. Additionally, one of Belize's most prized national treasures was discovered there - a large (almost 10 pounds or 5 kilograms) piece of jade elaborately carved into an image of the head of the Maya Sun God, Kinich Ahau. Two plazas and thirteen magnificent structures adorn this site, which is only one hour away f rom Belize City, and surrounded by pristine, tropical, broadleaf rainforest.",
"After departing from Altun Ha we can add on Belize City's historical tour of Belize at the Old Belize Museum, as well as soaking up some sun while relaxing at Cucumber Beach; or you may choose to double up with cave tubing and/or ziplining, or the Belize Zoo. There is also the ancient Maya city of Cahal Pech in the Cayo district, in the west of the country, near the Guatemalan border."
]; 

// const equestrianContent = [
//     "Perhaps you would prefer horseback riding and picnic at Smuggler's Run - Belize’s newest and latest theme park and exciting destination, invites the visitor to experience a wide combination of activities from equestrian adventures to birding, bicycle trails, go-carts, butterfly and other wildlife wonders, orchids, horse ‘n buggy rides, canoeing, kayaking, and great fun at our Barnhouse Bar’nBoca.",
//     "Situated on the famous Salt Creek Estate, a mere 22 miles on the Northern Highway, through quaint Creole villages such as Ladyville and Sandhill, with pine ridge, savannah and secondary rainforest vistas, you will be on ground once occupied for thousands of years by the greatest civilization known to man in its day, the Maya, then used by European smugglers to ply their trade, and illegal logwood cutters, this new theme park offers such famous history and at the same time, exciting activities for our guests. Ride well trained paint, appaloosa or palomino ponies through great rainforest and savannah trails or participate in any of our other adventures. Welcome to the adventure filled Smuggler’s Run!",
// ]

const nimLiIncludes = ["An authentic Belizean meal",
                       "Comfortable, air-conditioned transport",
                       "Water"]; 
const nimLiWhatToBring = ["A good pair of hiking/athletic shoes",
                          "Sunscreen",
                          "Bug spray"];

//Variables to shorten the content for the waterfalls tour
const waterfallsWhatToBring = ["Clothes and shoes you are comfortable with getting wet",
                               "A Change of clothes",
                               "Towel",
                               "Sunscreen",
                               "Bug spray"];

const altunHaIncludes = ["An authentic Belizean Kriol Dinna: coconut rice, rice and beans with chicken with salad or fried plantains",
                         "Comfortable, air-conditioned transport",
                         "Water"]; 

//Variables to shorten the content for the ziplining tour
const zipliningContent = [
    "Many of our guests like to mix up the relaxing, leisurely experience of cave tubing with adrenaline rush of ziplining.",
    "Zip Line technology borrows heavily from that used of rock and mountain climbing, and features a double line with redundancy to provide a safe environment for the users. Being the first Canopy tour in Belize, the lines are built to standards approved by the Association for Challenged Course Technologists. The Aerial Trek Canopy Tour offers the adventurous tourists a thrilling opportunity to soar through the jungle treetops high above the forest floor. The canopy tour involves traversing from one platform to another along a steel cable suspended up to 80 feet above the forest floor.",
    "Upon arrival you will be given a safety briefing and outfitted with safety gear that includes a body harness, pulleys, helmet and gloves. After you are fully geared up and checked, your tour then starts off with a short educational hike up to the first platform.",
    "Here a new world unfolds as you gently slide along on a steel cable to the next tree platform. All tours are escorted through the forest canopy by a lead guide and a follow guide to ensure your safety. The guides explain the different areas of the ecosystem you are experiencing. Your tour will take you over eight platforms. Along the way you will enjoy the bird’s eye view of our beautiful pristine ecosystem. The final run is to a platform only a few feet off the ground - you walk down a small flight of stairs.",
    "The proximity of the zipline to the Caves Branch park allows you plenty of time to enjoy both tours! The zipline is next to the park where cave tubing starts from. And rest assured, you will be back to the docks in good time, comfortably, and with some shopping time.",
    "Whatever tour activity you require, Tom Jr. and his guides can supply – Belize Mayan sites, wildlife centers, horseback riding, cave tubing and ziplining, river and sea fishing tours, snorkeling, hotel and local airline bookings, you name it. We are destination managers who have been in tourism for the past 21 years, and have maintained business relationships with tourism suppliers all over Belize.",
    "*Note: the minimum age for the zipline tour is 4 years old. The maximum weight allowed is 300 lbs.*"
];
const zipliningIncludes = ["An authentic Belizean meal",
                           "Comfortable, air-conditions transport",
                           "Water",
                           "Ziplining gear and safety helmet"];
const zipliningWhatToBring = ["A good pair of hiking/athletic shoes",
                              "Sunscreen",
                              "Bug spray",
                              "Courage of the Jaguar"];

const zipliningNotes = <div>Maximum weight limit for ziplining is 300lbs/person. Minimum age for ziplining is 4 years old. Our party meets this criteria</div>;
const caveTubingNotes = <div>By booking this tour you are consenting to the cave tubing <a className = "prettyLink" href="/safety">safety guidelines</a></div>

//Variables to shorten the content for the inland blue hole tour 
// const inlandBlueHoleContent = ["The Mayan shamans used these series of amazing cave systems for their religious ceremonies as they believed this was the ancient dwelling place of their dark lords. The underworld known as Xibalba translated into hell must be traversed by the soul in order to ascend into heaven.",
//                                "In the modern age we explore these caves and learn and appreciate all about the mayan culture. We top it off with a swim in the natural pool created by a sinkhole in the side of the mountain known as the inland blue hole."]; 
// const inlandBlueHoleWhatToBring = waterfallsWhatToBring; 

//Variables to shorten the content of the Cave Tubing Tour
const caveTubingContent = [
    "Cave Tubing is at Sibun Caves Branch Archaeological Park, which provides visitors the best opportunities to explore the magnificent cave system there, floating through safely and leasurely, while admiring the fantastic formations both inside and outisde the caves.",
    "Your cave tubing tour will start with a 30 minute hike through well kept and easy to traverse nature trails, which take you through two small dry caves with gorgeous stalactite and stalagmite formations and awesome crystal curtains. The flora of the rainforest is typical of a well preserved tropical broadleaf eco-system, with many famous hardwoods, medicinal plants, and some farming produce such as guava, mamei, pineapple, sugarcane, mango, avocado, and coconut trees.",
    "As you reach the river system of the Sibun Caves Branch, enjoy a cool splash in this undisturbed, unpolluted spring and rain fed waterway which punched a cavernous cave system through the hills of the Sibun Range during the early cretaceous period over 100 million years ago! But there is more to this system than meets the eye. The advent of the famous Maya Civilization to this area known as Meso-America, meant the use of the caves as an earthly journey through the mythological underworld of the Maya faith and confrontation with the nine dark lords who governed the nine levels of that world.", 
    "As you float down the river, you will experience the wonder of animal and bird-shaped stalactite formations once held sacred by those Mayan shamans, so be sure to bring along a waterproof camera, even a disposable one, with a flash unit.",
    "At the end of your cave tubing journey through this astounding cave system, you will float out into the open, under the canopy of the majestically tall trees lining the riverbanks, until you end up right back where you started walking. This makes for an easy and totally enjoyable circuit, and allows you the opportunity to relax and reflect after your adventure.",
    "Included in your tour: A/C van or bus with cellular phones for constant contact, cold bottled water, all professional cave tubing and safety equipment, highly trained guides, river safety staff, security for personal items, modern bathrooms, park entrance fees, showers and changing rooms provided for you at the site.",
];

//Variables to shorten the content of the Belize Zoo Tour 
const belizeZooContent = ["The Belize Zoo is a tropical savanna which exhibits dozens of animals, birds, and reptiles, all native to Belize. Well over a hundred species of indigenous wildlife will greet you feet away in this famous wildlife center, from crocodile to scarlet macaws, ocelots, peccaries, storks, otters, Harpy Eagle, kinkajou, crocodiles the five cats of Belize (jaguar – including a black jaguar – ocelot, puma, margay and jaguarondi), the Central American Tapir (Belize’s national animal), Keel Billed Toucan (Belize’s National Bird), and many others. A camera is a must!",
    "Both spider and howler monkey cavort on the tree branches, with white tailed deer and wild pigs running a round in the undergrowth. The natural environment created by the zookeepers keeps the wildlife feeling right at home. There is well-stocked handicraft center at the site.",
];

const CRUISE_EMBARKMENT = 0;

let tours = [
    { cayes: true, alts: lamanaiAlts, priceChild: 100, slideImages: importAll(require.context('./images/lamanai', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: lamanaiContent, price: 120, name: "lamanai", cardImage: lamanaiImage, url: "/tours/lamanai", text: "Lamanai Ruins & River Ride", cruise: true, ShortDesc: "Take a Jungle Cruise Ride to the Lamanai Mayan Ruins."},
    { cayes: true, alts: xunantunichAlts, priceChild: 110, slideImages: importAll(require.context('./images/xunantunich', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: xunantunichContent, price: 130, name: "xunantunich", cardImage: xunantunichImage, url: "/tours/xunantunich", text: "Xunantunich Mayan Ruins", cruise: false, ShortDesc: "View all of belize from the top of this ceremonial Mayan Site", },
    { cayes: true, alts: altunHaAlts, priceChild: 55, slideImages: importAll(require.context('./images/altunHa', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: altunHaIncludes, content: altunHaContent, price: 55, name: "altunHa", cardImage: altunHaImage, url: "/tours/altunHa", text: "Altun Ha Mayan Ruins", cruise: true, ShortDesc: "Visit the Jade Head at the magnificant Altun Ha Mayan Ruins", },
    { cayes: true, notes: zipliningNotes, alts: zipliningAlts, priceChild: 70, slideImages: importAll(require.context('./images/ziplining', false, /\.(jpg)$/)), whatToBring: zipliningWhatToBring, includes: zipliningIncludes, content: zipliningContent, price: 105,name: "ziplining", cardImage: zipliningImage, url: "/tours/Ziplining", text: "Rainforest Ziplining", cruise: true, ShortDesc: "Race the howler monkeys through jungle canopies in a breathtaking experience." },
    { cayes: true, notes: caveTubingNotes, alts: caveTubingAlts, priceChild: 35, slideImages: importAll(require.context('./images/caveTubing', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: caveTubingContent, price: 55,name: "caveTubing", cardImage: caveTubingImage, ShortDesc: "Take a relaxing tube ride through the rainforest and ancient Mayan ritual caves", url: "/tours/CaveTubing", text: "Cave Tubing", cruise: true },
    { cayes: true, alts: zipliningAlts, priceChild: 85, slideImages: importAll(require.context('./images/zoo', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: belizeZooContent, price: 145,name: "zoo", cardImage: zooImage, ShortDesc: "", url: "/tours/BelizeZoo", text: "Belize Zoo", ShortDesc:"See all 5 of exotic cats native to Belize and many other exotic tropical species.", cruise: true }
];

//Removed the equestrian and cahal pech tours due to limited media content. If we add it back, just uncomment the below line and append it to the tour list. 
/*    { priceChild: 60, slideImages: importAll(require.context('./images/equestrian', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: equestrianContent, price: 85, name: "equestrian", cardImage: nimLiImage, url: "/tours/equestrian", text: "Equestrian Adventure", cruise: true, ShortDesc: "Ride ponies through rainforest and savannah", },*/ 
/*    { priceChild: 100, slideImages: importAll(require.context('./images/cahalPech', false, /\.(jpg)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: cahalPechContent, price: 100, name: "cahalPech", cardImage: nimLiImage, url: "/tours/cahalPech", text: "Cahal Pech Mayan Ruins", cruise: true, ShortDesc: "Discover the secrets of this Mayan market center"},*/ 
/*    { price: 250, cruise: false, name: "tikal", cayes: false} */ 
/*    { price: 250, cruise: false, cayes: true, name: "atm" */

tours.map(tour => {
    tour.slideImages["name"] = tour.name;
});

let bundles = [
    {
        name: "Cave Tubing & Altun Ha Mayan Ruins",
        tours: ["caveTubing", "altunHa"], 
        price: 90, 
        priceChild: 70,
        cruise: true, 
        cayes: true 
    },
    /*{
        tours: ["altunHa", "ziplining"], 
        price: 110, 
        priceChild: 100
    },*/ 
    {
        name: "Cave Tubing, Altun Ha Mayan Ruins & Rainforest Ziplining", 
        tours: [ "caveTubing", "altunHa", "ziplining"], 
        price: 125, 
        priceChild: 110,
        cruise: false, 
        cayes: true
    },
    {
        name: "Cave Tubing & Rainforest Ziplining", 
        tours: ["caveTubing", "ziplining"],
        price: 90,
        cruise: true, 
        cayes: true
    },
    {
        name: "Cave Tubing & Belize Zoo",
        tours: ["caveTubing", "zoo"],
        price: 100,
        cruise: true, 
        cayes: true
    },
    {
        name: "Cave Tubing, Altun Ha Mayan Ruins & Belize Zoo",
        tours: ["caveTubing", "zoo", "altunHa"],
        price: 145, 
        cruise: false, 
        cayes: true
    },
    {
        name: "Cave Tubing, Ziplining, & Belize Zoo",
        tours: ["caveTubing", "ziplining", "zoo"], 
        price: 145,
        cruise: false,
        cayes: true
    },
    /*{
        tours: ["altunHa", "zoo"], 
        price: 85, 
        priceChild: 75
    },*/ 
    {
        name: "Cave Tubing & Xunantunich Mayan Ruins", 
        tours: ["caveTubing", "xunantunich"], 
        price: 110, 
        priceChild: 110,
        cruise: false, 
        cayes: true
    },/*
    {
        tours: ["xunantunich", "caveTubing", "ziplining"], 
        price: 180, 
        priceChild: 140
    },*/ 
];
/* The below bundles have been removed, or require more information to be added to the site. 
 * When we have enough information (media, desc, etc.), we can cut & past the below bundles
 */
/*
    {
        tours: ["caveTubing", "atv"],
        price: 95, 
        cruise: true, 
        cayes: true
    },
    {
        tours: ["caveTubing", "altunHa", "atv"],
        price: 145, 
        cruise: true, 
        cayes: true
    },
        {
        tours: ["caveTubing", "zoo", "atv"],
        price: 175, 
        cruise: false, 
        cayes: true
    }
*/

/* Given a bundle's array of tour names and a bundles price, 
 * iterates through available tours and computes the difference 
 * between if the tours were purchased with individual pricing 
 * from the bundle price to show the users how much they're saving
*/
function computeSavings(tourList, bundlePrice){
    let sumTourCosts = 0; 
    for(let i=0;i<tourList.length;++i){
        sumTourCosts+=tours.find(o => o.name===tourList[i]).price;
    }
    return sumTourCosts-bundlePrice; 
}
//Compute the savings for each bundle 
bundles.map(bundle =>(
    bundle.savings = computeSavings(bundle.tours, bundle.price)
));

const homeTooltips = {
    cruiseTooltip: "Taking a cruise tender to the Belize City Cruise Terminal",
    hotelTooltip: "Staying overnight in Belize City area",
    cayeTooltip: "Taking the Caye Caulker Ferry from San Pedro or Caye Caulker to Belize City Docks"
}

class App extends React.Component {

    constructor(props) {
        super();

        this.state = {
            embarkment: 0
        };

        this.setEmbarkment = this.setEmbarkment.bind(this);
    }

    setEmbarkment(val) {
        this.setState({embarkment: val});
    }

    bookingCallback = (booked) => {
    }
    render() {
    return (
        <div>
            {/* <TripadvisorWidget/> */}
            <Router>
                <Switch>
                    <Route path="/" exact component={() => <Home
                        siteUrls = {siteUrls}
                        bookingCallback = {this.bookingCallback}
                        tooltips = {homeTooltips}
                        tours={tours}
                        bundles = {bundles}
                        followUs = {followUs}
                        embarkment = {this.state.embarkment}
                        embarkmentChanged = {this.setEmbarkment}
                        homeSlideImages={importAll(require.context('./images/home', false, /\.(jpg)$/))}/>}/>
                    <Route path="/About" exact component={() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <About /></>} />
                    <Route path="/contact" render={() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <Contact tours={tours} /></>} />
                    <Route path="/BookNow" render={() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <BookNow tours={tours} bundles = {bundles}/></>} /> 
                    <Route path="/ThankYou" render={() => <>
                        <ThankYou />
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/></>} />
                    <Route path="/ThankYouContact" render={() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <ThankYouContact />
                    </>} />
                    <Route path="/FAQ" render = {() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <FAQ/></>}/>
                    <Route path = "/Covid" render = {() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <Covid /></>}/>
                    <Route path="/Safety" render = {() => <>
                        <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                        <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                        <Safety/></>}/>
                    <Route path="/tours/allTours" render={() => <AllTours tours={tours} />} /> 
                    {
                        tours.map(tour => (
                            <Route path={tour.url} exact component={() => <>
                                <MobileNavbar fixed = {false} siteUrls={siteUrls} tours={tours}/>
                                <DesktopNavbar fixed = {false} siteUrls={siteUrls} tours={tours} sticky={true}/>
                                <TourBody tourInfo={tour} tours={tours} bundles = {bundles}/>
                                </>}/>
                            ))
                    }
                </Switch> 
            </Router>
            <Footer followUs={followUs} bottomBarGridItems={siteUrls} />
        </div>
    );}
}

export default App;
