import React from "react";
import TextField from './TextField.js';
import ReactTooltip from "react-tooltip";

import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import { LocationCity } from '@material-ui/icons';
import {GiIsland} from 'react-icons/gi'

class BookingTransportationGrid extends React.Component{
    
    constructor(props){
        super(); 

        this.state = {
            checked: props.initial
        }
    }

    processLocation(event){
        event.target.name === "hotel" ?  this.setState({checked: 1}) :
        event.target.name === "cruise" ? this.setState({checked: 2}) :
                                         this.setState({checked: 3});
    }

    render(){
        return(
        <>
        <div className = "bookingTransportationGridContainer">
            <div className = "bookingTransportationGrid">
                <div className = {this.state.checked === 1 ? "bookingTransportationOption blue" : 
                                  this.state.checked === null ? "bookingTransportationOption"
                                  : "bookingTransportationOption red"} onClick = {(event) =>
                    {
                        if(this.state.checked === 1) this.setState({checked: null});
                        else this.setState({checked: 1});
                        this.props.callbackFunction(this.state.checked === 1 ? null : 1);
                        this.props.hotelCallback("cayes", true);}}>
                    <LocationCity className = {this.state.checked === 1 ? "bookingEmbarkment selected" : "bookingEmbarkment"}></LocationCity>
                    <div className = "bookingTransportationTitle">
                        Staying at Hotel in Belize City
                    </div>
                </div>
                <div className = 
                        {this.state.checked === 2 ? "bookingTransportationOption blue" : 
                        this.state.checked === null ? "bookingTransportationOption"
                        : "bookingTransportationOption red"} 
                    onClick = {(event) => 
                    {
                        if(this.state.checked === 2) this.setState({checked: null});
                        else this.setState({checked: 2});
                        this.props.callbackFunction(this.state.checked === 2 ? null : 2);}}>
                    <DirectionsBoatFilledIcon className = {this.state.checked === 2 ? "bookingEmbarkment selected" : "bookingEmbarkment"}></DirectionsBoatFilledIcon>
                    <div className = "bookingTransportationTitle">
                        Taking Cruise Tender to Belize City
                    </div>
                </div>
                <div className = {this.state.checked === 3 ? "bookingTransportationOption blue" : 
                    this.state.checked === null ? "bookingTransportationOption"
                                  : "bookingTransportationOption red"} onClick = {(event) =>
                    {
                        if(this.state.checked === 3) this.setState({checked: null});
                        else 
                        {
                            this.setState({checked: 3});
                            this.props.hotelCallback("cayes", true)
                        }
                        this.props.callbackFunction(this.state.checked === 3 ? null : 3);}}>
                    <GiIsland className = {this.state.checked === 3 ? "bookingEmbarkment selected" : "bookingEmbarkment"}></GiIsland>
                    <div className = "bookingTransportationTitle">Taking Caye Caulker Water Taxi</div>
                </div>
            </div>
        </div>
                {this.state.checked === 1 ?
                    <TextField for="hotelName"
                    fullWidth = {true}
                    criteriaFunction={(x)=>{return x.trim().length > 5}}
                    placeholder="Belize City Hotel"
                    labelText="Hotel Name/Address"
                    changeFunction={this.props.hotelCallback}/> : null}
                {this.state.checked === 2 ?
                    <TextField for="cruiseName"
                    fullWidth = {true} 
                    criteriaFunction={(x)=>{return x.trim().length > 5}}
                    placeholder="Carnival Cruise"
                    labelText="Cruise Name"
                    changeFunction={this.props.hotelCallback}/> : null } 
            <ReactTooltip/>
        </>
        )
    }
}
export default BookingTransportationGrid; 