// Core Components
import React from 'react';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import { LocationCity } from '@material-ui/icons';
import { Discount } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@material-ui/core';
import {GiIsland} from 'react-icons/gi'

// Styles
import './TourBundleTable.css';

// Custom Components
// import GridOptionsShowToggle from '../GridOptionsShowToggle';
import TourCardBundleItem from './TourCardBundleItem.js';

// Global Variables
const priceLogistics =
    "All tours are booked online with a 10% USD up-front reservation fee pre-included in the specified prices. This is paid via a secure Paypal portal and no Paypal account is necessary to make this transaction. The remaining 90% is to be paid to the guide at the beginning of your tour in either USD or BZE. $1 USD is $2 BZE.";
const embarkmentLogistics =
    "Certain tour bundles require longer time for transportation and tour duration. In order to guarantee that passengers are always able to arrive on time for the final cruise tenders or caye ferries departing from Belize City in the evening, only certain bundles are made available for these embarkment methods."
class TourBundleRow extends React.Component{
    constructor(props){
        super(); 
        this.state = {
            toggle: false
        };
        this.toggleCallback = this.toggleCallback.bind(this);  
    }

    toggleCallback()
    {
        let toggled = this.state.toggle; 
        this.setState({toggle:!toggled});
    }

    render() {
        return(
            <div className = "slide bodyPadding">
                <div className = "padded-bottom">
                    <Accordion defaultExpanded = {true} expanded = {true} className="accordion noMargin noIcon">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                            <div className = "tourBundleHeaderContainer">
                                <div class="padded-left">
                                    {/* <Button variant="contained"
                                            className = "loadMoreToursBtn yellow"
                                            size = "large"
                                            onClick = {()=>{this.props.clickBundleFunction(this.props.bundle.tours);}}>
                                        Book Bundle ${this.props.bundle.price}
                                    </Button> */}
                                </div>
                                <div className = "tourBundleHeaderTitle">
                                    <h3 class="padded-sides">
                                        {this.props.bundle.name}
                                    </h3>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                        <ReactTooltip class="tooltip"/>
                        <div class="tourBundleRowContainer">
                        <div class = "tourBundleRow">
                            <div class = "tourBundleColumn toursIncluded flexMax">
                                <h4> Tours Included </h4>
                                <div class="padded-top-bottom">
                                    {this.state.toggle?
                                    <div className="tourBundleItems">{this.props.bundle.tours.map((tour, index) =>(
                                        <div className="tourCardBundleItem"
                                             key = {index}>
                                            <div className="tourCardBundle"
                                                 style={{backgroundImage: `url(${this.props.tours.find(o=>o.name===tour).cardImage})`}}>
                                                <a className="tourBundleGridLink"
                                                   href={this.props.tours.find(o => o.name===tour).url}>
                                                    {this.props.tours.find(o => o.name===tour).text}
                                                    <span data-tip = {this.props.tours.find(o => o.name===tour).ShortDesc}>
                                                        <InfoIcon className="infoIcon"/>
                                                    </span>
                                                </a>
                                                <a href = {this.props.tours.find(o => o.name===tour).url} className = "seeTour">View this Tour</a>
                                            </div>
                                        </div>))}
                                        <ReactTooltip class="tooltip"/>
                                    </div> :
                                    <div>
                                    {this.props.bundle.tours.map((tour, index) => (
                                        <div key = {index} data-tip = {this.props.tours.find(o => o.name===tour).ShortDesc} >
                                            {this.props.tours.find(o => o.name===tour).text}
                                        </div>
                                    ))}
                                    </div>
                                    }
                                </div>
                                {/* <GridOptionsShowToggle toggled={this.state.toggle}
                                    clickEvent={this.toggleCallback}
                                    toggleOn={"Show List Format"}
                                    toggleOff={"Show Card Format"}/> */}
                            </div>
                            <div class = "tourBundleColumn flexMax">
                                <h4> Price 
                                    <span
                                          data-tip={priceLogistics}>
                                        <InfoIcon className="infoIcon" fontSize="small"/>
                                    </span>
                                </h4>
                                <div class="padded-top">
                                    <div>
                                        ${this.props.bundle.price} USD Per Person
                                    </div>
                                    <div class="optionsItem">
                                        <Discount/> Save ${this.props.bundle.savings} USD per person on this bundle!
                                    </div>
                                </div>
                            </div>
                            <div class = "tourBundleColumn flexMax">
                                <h4> Available For 
                                    <span
                                          data-tip={embarkmentLogistics}>
                                        <InfoIcon className="infoIcon" fontSize="small"/>
                                    </span>
                                </h4>
                                <div class="padded-top">
                                        {this.props.embarkment === 0 ?
                                            <>
                                                <div class="optionsItem">
                                                    <span class="optionsItemText selected">Cruise Visitors</span>
                                                    <DirectionsBoatFilledIcon fontSize="large" className="iconSelected"/>
                                                </div>
                                                {this.props.bundle.cayes ? 
                                                    <div class="optionsItem">
                                                        <span class="optionsItemText">Caye Visitors</span>
                                                        <GiIsland className="MuiSvgIcon-fontSizeLarge"/>
                                                    </div>
                                                : <></>}
                                                <div class="optionsItem"> <span class="optionsItemText"> Visitors Staying in Belize City</span><LocationCity fontSize="large"/></div>
                                            </>
                                        : this.props.embarkment === 1 ?
                                            <>
                                            <div class="optionsItem">
                                                <span class="optionsItemText selected">Caye Visitors</span>
                                                <GiIsland className="iconSelected MuiSvgIcon-fontSizeLarge"/>
                                            </div>
                                            {this.props.bundle.cruise ? 
                                                    <div class="optionsItem">
                                                        <span class="optionsItemText">Cruise Visitors</span>
                                                        <DirectionsBoatFilledIcon fontSize="large"/>
                                                    </div>
                                                : <></>}
                                            <div class="optionsItem"> <span class="optionsItemText"> Visitors Staying in Belize City</span><LocationCity fontSize="large"/></div>
                                            </>
                                        :
                                            <>
                                            <div class="optionsItem">
                                                <span class="optionsItemText selected">Visitors Staying in Belize City</span>
                                                <LocationCity className="iconSelected" fontSize="large" />
                                            </div>
                                            {this.props.bundle.cruise ? 
                                                <div class="optionsItem">
                                                    <span class="optionsItemText">Cruise Visitors</span>
                                                    <DirectionsBoatFilledIcon fontSize="large"/>
                                                </div>
                                            : <></>}
                                            {this.props.bundle.cayes ? 
                                                <div class="optionsItem">
                                                    <span class="optionsItemText">Caye Visitors</span>
                                                    <GiIsland className = "MuiSvgIcon-fontSizeLarge"/>
                                                </div>
                                            : <></>}
                                            </>}
                                </div>
                            </div>
                        </div>
                        
                </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="tourBundleItems">{this.props.bundle.tours.map((tour, index) =>(
                    <TourCardBundleItem key = {index}
                        fullTour = {this.props.tours.find(t => t.name === tour)}/>
                    ))}
                    <ReactTooltip class="tooltip"/>
                </div>
                <div className = "flexed centered padded-top-bottom">
                    <Button variant="contained"
                            className = "loadMoreToursBtn yellow"
                            size = "large"
                            onClick = {()=>{this.props.clickBundleFunction(this.props.bundle.tours);}}>
                        Book Bundle ${this.props.bundle.price}
                    </Button>
                </div>
            </div>
        );
    }
}
export default TourBundleRow; 