import React from 'react';

import Day from './Day.js';
import XIcon from './XIcon.js';

import calendarImg from '../images/logos/calendarIcon.png'; 

const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}

const daysInMonth = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
}; 


class TourDate extends React.Component {

    constructor(props) {
        super(); 
        this.inputChange = props.inputChange; 
        this.date = new Date(); 
        this.currYear = this.date.getFullYear();
        this.currMonth = this.date.getMonth(); 
        this.days = daysInMonth[this.date.getMonth() % 12 + 1]; 
        this.currDays = [];
        this.tempArr = []; 
        let dateString = this.date.getMonth() < 9 ? "0" + (this.date.getMonth()+1) : (this.date.getMonth()+1)
        dateString += "/";
        let temp = ""; 
        temp += (this.date.getDate() + 1) < 10 ? "0" : "";
        dateString+= temp+(this.date.getDate() + 1) + "/" + (this.date.getFullYear() - 2000)

        //Defaults to asking about a tour happening tomorrow 
        this.state = {
            date: dateString,
            status: true,
            show: false, 
            activeStep: this.currMonth, 
            childSelects: this.tempArr 
        }
        this.classVar = this.state.date; 

        this.handleDayClick = this.handleDayClick.bind(this); 

        this.initCurrDays(); 
        this.setCalendarDate(); 
    }


    setCalendarDate(str) { 
       if (this.state.status && this.state.date) {
            let selectedDatePartitions = this.state.date.split('/'); 
            this.handleDayClick(parseInt(selectedDatePartitions[1]));
            this.setState({
                activeStep: selectedDatePartitions[0]
            })
       }
    }

    handleDayClick(dayNum) {
        this.tempArr = this.state.childSelects;
        for (let i = 0; i < this.tempArr.length; ++i) {
            this.tempArr[i] = false; 
            if (i === dayNum - 1) {
                this.tempArr[i] = true;
                this.currDays[i] = <Day value={i}
                    past={this.currDays.length < this.date.getDate()}
                    clickFunction={this.handleDayClick}
                    selectedDay={true} />;
            }
        }

        if (this.state.activeStep === this.currMonth && dayNum <= this.date.getDate()) return; 
        let dateString = ((this.state.activeStep%12)+1) < 10 ? "0" : ""; 
        dateString += ((this.state.activeStep%12)+1) + "/"; 
        if (dateString.length > 3) dateString = dateString.slice(1);  
        let temp = dayNum < 10 ? "0" : "";
        dateString += temp + dayNum + "/" + (this.currYear + Math.floor(this.state.activeStep / 12) - 2000);

        this.setState({
            childSelects: this.tempArr,
            numDays: this.currDays,
            date: dateString,
            status: true
        }); 
        this.inputChange(dateString, true); 
    }

    incrMonth() {
        if (this.state.activeStep > this.date.getMonth() + 24) return;
        this.setState(state => ({ activeStep: this.state.activeStep + 1 }));
    }


    decrMonth() {
        if (this.state.activeStep <= this.date.getMonth()) return;
        this.setState(state => ({ activeStep: this.state.activeStep - 1 }));
    }


    initCurrDays() {
        if (!this.tempArr) this.tempArr = [];
        let i = 1;
        while (this.currDays.length < this.days) {
            this.tempArr.push(false);
            this.currDays.push(<Day value={i}
                past={this.currDays.length < this.date.getDate()}
                clickFunction={this.handleDayClick}
                selectedDay={this.tempArr[i - 1]} />);
            ++i;
        }
    }

    setDays(x) {
        if (x < 0 && this.activeStep - 1 === this.date.getMonth()) {
            for (let i = 1; i <= this.date.getDate() && i <= this.currDays.length; ++i) {
                this.currDays[i - 1] = <Day value={i} past={true} number={i} />;
            }
        }
        else {
            for (let i = 1; i <= this.date.getDate() && i <= this.currDays.length; ++i) {
                this.currDays[i - 1] = <Day value={i} past={false} number={i} />
            }
        }
        let numDays = daysInMonth[this.activeStep % 12 + 1 + x];
        this.currDays = [];
        let i = this.currDays.length + 1;
        while (this.currDays.length < numDays) {
            this.currDays.push(<Day value={i} past={false} number={i} />);
            ++i;
        }
        while (this.currDays.length > numDays) {
            this.currDays.pop();
        }
    }

    render() {
        return (
            <div className="contactFormField maxWidth calendarFieldContainer">
                <label>
                    Desired Tour Date
                    <div className={this.state.show ? "calendarIcon space" : "calendarIcon"}
                        onClick={() => { this.setState({ show: !this.state.show }) }}>
                        <img src={calendarImg} height="30px" width="30px" id="calendarImg" alt="book your tour date" />
                    </div>
                </label>
                <input className={this.state.status ? "formControl calendarInput green" : "formControl calendarInput red"}
                       name="date"
                       id="date"
                       placeholder="mm/dd/yy"
                       value={this.state.date}
                       onChange={(event) => {
                           let regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[1-9]|2[1-9])$/; 
                           let tempStatus = regex.test(event.target.value.trim()) && parseInt(event.target.value.slice(-2)) < 24;
                           this.setState({
                               date: event.target.value,
                               status: tempStatus
                           });
                           if (tempStatus) this.setCalendarDate("input"); 
                           this.inputChange(event.target.value, tempStatus); 
                       }}
                    required>
                </input>
                <div>
                    {this.state.show ?
                        <div className="calendar">
                            <div className="calendarExit">
                                <div className="XIconContainer"
                                     onClick={() => {
                                           this.setState({ show: !this.state.show });
                                     }}>
                                    <XIcon /> 
                                    <img src={calendarImg}
                                         height="30px"
                                         width="30px"
                                         alt="book your tour date"
                                         className="calendarIconCalendar" />
                                </div> 
                            </div> 
                            <div className="calendarHeader">
                                <span className="prevMonth" id="prevMonth" onClick={(event) => { this.decrMonth(); }}> &#10094;</span>
                                <div id="calendarMonth">{months[this.state.activeStep % 12 + 1]}</div>
                                <div id="calendarYear">{this.currYear + Math.floor(this.state.activeStep / 12)}</div>
                                <span class="nextMonth" id="nextMonth" onClick={(event) => { this.incrMonth(); }}> &#10095;</span>
                            </div>
                            <div className="calendarBody">
                                <div className="calendarBodyPadding">
                                    <Day currMonth={this.state.activeStep} value={1} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[0]} />
                                    <Day currMonth={this.state.activeStep} value={2} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[1]} />
                                    <Day currMonth={this.state.activeStep} value={3} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[2]} />
                                    <Day currMonth={this.state.activeStep} value={4} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[3]} />
                                    <Day currMonth={this.state.activeStep} value={5} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[4]} />
                                    <Day currMonth={this.state.activeStep} value={6} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[5]} />
                                    <Day currMonth={this.state.activeStep} value={7} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[6]} />
                                    <Day currMonth={this.state.activeStep} value={8} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[7]} />
                                    <Day currMonth={this.state.activeStep} value={9} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[8]} />
                                    <Day currMonth={this.state.activeStep} value={10} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[9]} />
                                    <Day currMonth={this.state.activeStep} value={11} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[10]} />
                                    <Day currMonth={this.state.activeStep} value={12} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[11]} />
                                    <Day currMonth={this.state.activeStep} value={13} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[12]} />
                                    <Day currMonth={this.state.activeStep} value={14} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[13]} />
                                    <Day currMonth={this.state.activeStep} value={15} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[14]} />
                                    <Day currMonth={this.state.activeStep} value={16} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[15]} />
                                    <Day currMonth={this.state.activeStep} value={17} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[16]} />
                                    <Day currMonth={this.state.activeStep} value={18} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[17]} />
                                    <Day currMonth={this.state.activeStep} value={19} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[18]} />
                                    <Day currMonth={this.state.activeStep} value={20} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[19]} />
                                    <Day currMonth={this.state.activeStep} value={21} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[20]} />
                                    <Day currMonth={this.state.activeStep} value={22} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[21]} />
                                    <Day currMonth={this.state.activeStep} value={23} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[22]} />
                                    <Day currMonth={this.state.activeStep} value={24} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[23]} />
                                    <Day currMonth={this.state.activeStep} value={25} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[24]} />
                                    <Day currMonth={this.state.activeStep} value={26} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[25]} />
                                    <Day currMonth={this.state.activeStep} value={27} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[26]} />
                                    <Day currMonth={this.state.activeStep} value={28} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[27]} />
                                    <Day currMonth={this.state.activeStep} value={29} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[28]} />
                                    <Day currMonth={this.state.activeStep} value={30} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[29]} />
                                    <Day currMonth={this.state.activeStep} value={31} past={false} clickFunction={this.handleDayClick} selectedDay={this.state.childSelects[30]} />

                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        );
    }

}

export default TourDate;