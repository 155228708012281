import React from 'react'; 

function SmallHamburger({ toggled }) {
    return (
        <div className={toggled ? "smallBurger toggled" : "smallBurger"}>
            <div className="left"></div>
            <div className="mid"></div>
            <div className="right"></div> 
        </div>
    ); 
}

export default SmallHamburger; 