import React from 'react';
import TourBundleTable from './TourBundleTable/TourBundleTable.js'
// import TourParagraph from './TourParagraph';

class BundleTours extends React.Component{
    constructor(props){
        super(); 
        this.options = ["Hotel Visitors", "Caye Visitors", "Cruise Passengers"]; 
        this.filterCallback = this.filterCallback.bind(this); 
        this.state = {
            filter: null
        }; 
    }

    filterCallback(e){
        this.setState({
            filter: e.target.innerHTML
        });
    }

    render(){
        return(
            <div className = "transportationOptionsContainer">
                <div className = "bodyMargin padded-top-bottom fpTitle">
                    <h2 className = "embarkmentTitle">
                        Bundle Tours and Save!
                    </h2>
                </div>
                <TourBundleTable tours = {this.props.tours}
                                embarkment = {this.props.embarkment}
                                clickBundleFunction = {this.props.clickBundleFunction}
                                bundles = {this.props.bundles}
                                filter = {this.state.filter}/>
            </div>
        );
    }
}
export default BundleTours;