import React from 'react';



class LongDescription extends React.Component {

    constructor(props) {
        super();
    }



    render() {

        return (
            <div>
                {this.props.content.map(textBlock => (<div className="introText">{textBlock}</div>))}
            </div> 
        );
    }
}



export default LongDescription;