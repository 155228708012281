import React from 'react'; 
import './../App.css';
import AboutGrid from './AboutGrid.js'; 
import '../Styles/About.css'; 

function About() {
    return (
        <div>
            <AboutGrid/> 
        </div>
    );
}

export default About;
