import React from 'react'; 
import TourCard from './TourCard.js'; 

function TourGrid({ bookTourFunction, tours, omit, filter, reducedHeight, bundles, embarkment }) {
    return (
        <div>
            <div className = "fpTitle padded-top-bottom">
                <h2 className = "embarkmentTitle">
                    Popular Tours
                </h2>
            </div>
            {bundles ?
            <div className= "grid-container-bundle"> 
                {bundles.map((bundle, index) => (
                    <div className="bundleRow" key = {index}> 
                    {bundle.tours.map(bundleTour => (
                        <TourCard  bookTourFunction = {bookTourFunction} tour = {tours.find(desc=>desc.name === bundleTour)} bundle = {true}/>
                    ))}
                        <div> 
                            <div></div> 
                            <div>Price Per Adult: {bundle.price}</div> 
                            <div>Price Per Child{bundle.priceChild}</div>
                        </div> 
                    </div> 
                ))}
            </div> 
            : 
            <div className={reducedHeight != null && reducedHeight ? "grid-container" : "grid-container maxed"}
            id="gridImages">
            {tours.map((tour, index) => (
                tour.name === omit ? null :
                embarkment === 0 && !tour.cruise ? null :
                embarkment === 1 && !tour.cayes ? null :
                <TourCard key={index} tour={tour} bookTourFunction = {bookTourFunction}/>
            ))}
            </div>} 
        </div>
    );
}

export default TourGrid;