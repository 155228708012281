import React from "react";

class TripadvisorWidget extends React.Component {
    constructor(props) {
        super();
    }

    componentDidMount() {
      
      }

    render() {
        return(
            <div className="tripadvisorWidgetContainer">
                {this.props.type && this.props.type=="1" ?
                <div id="TA_certificateOfExcellence314" className="TA_certificateOfExcellence">
                    <ul id="hsCuqs" className="TA_links e4AugE9sk">
                        <li id="DrMUgA" className="R2httqjU">
                            <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html">
                                <img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/>
                            </a>
                        </li>
                    </ul>
            </div> : 
            this.props.type && this.props.type=="2" ?
                <div id="TA_certificateOfExcellence312" className="TA_certificateOfExcellence"><ul id="XegQ1sEY7u" className="TA_links s0bE2yHfHiX"><li id="xuQaqRj1" className="dxAi7VHHDgAD"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type=="3" ?
            <div id="TA_certificateOfExcellence170" className="TA_certificateOfExcellence"><ul id="OVe68vcZVaSL" className="TA_links gMAiDW"><li id="pcyaiYDP" className="fPEIefP6z6"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type=="4" ?
            <div id="TA_certificateOfExcellence14" className="TA_certificateOfExcellence"><ul id="5SFdpVIXOl1F" className="TA_links DEet5z"><li id="QE3cvy" className="qVEg8o"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type=="5" ?
            <div id="TA_certificateOfExcellence661" className="TA_certificateOfExcellence"><ul id="2260LjFbFN" className="TA_links gMjxSuP"><li id="4TK4J3TGKr0" className="2DrYLNaC7"><a target="_blank" href="https://www.tripadvisor.com/"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type=="6" ?
            <div id="TA_certificateOfExcellence157" className="TA_certificateOfExcellence"><ul id="xfowXdKSrCa" className="TA_links 3qBF1KukzIy"><li id="zegBQR4eFNU" className="s092aTAsve"><a target="_blank" href="https://www.tripadvisor.com/"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type=="7" ?
            <div id="TA_certificateOfExcellence855" className="TA_certificateOfExcellence"><ul id="CILqIwmeAXL" className="TA_links ZeTjpu"><li id="a5jbTWrrL" className="4PZe7Y9jaDQ"><a target="_blank" href="https://www.tripadvisor.com/"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type=="8" ?
            <div id="TA_certificateOfExcellence819" className="TA_certificateOfExcellence"><ul id="FcQAQITXXT" className="TA_links ZnTKMQY"><li id="viwGd1B9" className="4muXk7VG6c0n"><a target="_blank" href="https://www.tripadvisor.com/"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type == "9" ?
            <div id="TA_certificateOfExcellence604" className="TA_certificateOfExcellence"><ul id="Hc2wry" className="TA_links hEZngIn"><li id="ScrCTInC0pV" className="EzjWXXiK"><a target="_blank" href="https://www.tripadvisor.com/"><img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/></a></li></ul></div>
            : this.props.type && this.props.type == "10" ?
            <div id="TA_excellent575" className="TA_excellent"><ul id="uGRAb3KJNNf" className="TA_links W50LIXd9HYdI"><li id="G6B97BtgfHN4" className="TdfgYZ1E7p"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html"><img src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg" alt="TripAdvisor" className="widEXCIMG" id="CDSWIDEXCLOGO"/></a></li></ul></div>
            : this.props.type && this.props.type == "11" ?
            <div id="TA_selfserveprop252" className="TA_selfserveprop"><ul id="hT3a4b4v1mn" className="TA_links y6Ii39RTaL4"><li id="XXoh1mzY" className="I3clZqSMgnco"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg" alt="TripAdvisor"/></a></li></ul></div>
            : <></>}
            </div>
        )
    }
} export default TripadvisorWidget;