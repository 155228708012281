import React from 'react'; 

function FacebookIcon() {
    return (
        <div className="facebookIcon">
            <div className="facebookCurve"></div> 
            <div className="facebookTRRound"></div>
            <div className="facebookLine"></div> 
        </div>
    );
}

export default FacebookIcon;