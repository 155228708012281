import React, { Component } from 'react';   //Use of class-based react components

// Common Components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';

class GridOptionItem extends Component{

    constructor(props){
        super();
        this.state = {
            show: false
        }
        this.toggleCallback = this.toggleCallback.bind(this);  
        this.initialSentence = props.option.description[0]; 
        this.small = props.small && props.small === true; 
    }


    toggleCallback()
    {
        let toggle = this.state.show; 
        this.setState({show:!toggle});
    }

    render(){
        let className = "gridOption";
        className = className + " full"

        return (
            <div style={{backgroundImage: `url(${this.props.option.backgroundImage})`}}
                 class = {className}
                 >

                <div class="flexed padded-bottom"
                    onClick = {() => {
                       this.props.embarkmentChange(this.props.option.embarkmentValue);
                    }}>
                    <h3 className="OptionTitle">{this.props.option.title}</h3>
                    {/* <div class="OptionCheckboxContainer">
                        <div className={this.props.option.embarkmentValue === this.props.embarkment ? "OptionCheckbox checked" : "OptionCheckbox"}>

                        </div>
                    </div> */}
                </div>
                <Accordion expanded = {this.state.show}
                    className="accordion padded-sides"
                    onChange = {() => {this.toggleCallback();}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <h3>
                          {this.props.option.shortDescription}
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails class="accordionDetails">
                        {this.props.option.description}
                    </AccordionDetails>
                </Accordion>
                <Button variant="contained"
                    onClick = {() => {
                        this.props.embarkmentChange(this.props.option.embarkmentValue);
                    }}
                    className = {`${this.state.show? "hidden" : ""} loadMoreToursBtn ${this.props.option.embarkmentValue !== this.props.embarkment ? "yellow" : ""} gridOptionItemButton`}>
                    {/* className={this.state.show ? "loadMoreToursBtn yellow gridOptionItemButton hidden" : "loadMoreToursBtn yellow gridOptionItemButton"}> */}
                    {this.props.option.embarkmentValue === this.props.embarkment ? <>Embarkment Selected <CheckIcon/></> : "Select Embarkment Method"}
                </Button>
            </div> 
        ); 
    }
}

export default GridOptionItem; 