import React from 'react'; 
import BottomBarLogoMenu from './BottomBarLogoMenu.js';
import TripadvisorWidget from './TripadvisorWidget.js';
import logoImg from './../images/cavetubingLogo.webp';

class Footer extends React.Component{

    constructor(props){
        super();
    }
//<script async src="https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=810&amp;locationId=1027073&amp;lang=en_US&amp;rating=true&amp;nreviews=4&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=true&amp;border=true&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>
    /*componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=340&amp;locationId=1027073&amp;lang=en_US&amp;rating=true&amp;nreviews=5&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=false&amp;border=true&amp;display_version=2";
        script.async = true;
        script.onload = 
        script.onload = () => {
            this.scriptLoaded();
            this.loadtrk=true
        }
      
        document.body.appendChild(script);
    }

    scriptLoaded() {
      }*/ 

    render(){
        return (
            <nav className={`bottomBar ${this.props.fullScreen ? "fullScreen" : ""}`}>
                <div className="bottomBarImgContainer">
                        <div className="footerLogoSpace">
                        <a href = "/">
                            <img className="logoImg"
                                alt="Enjoy a Belize cave tubing tour with Major Tom and Minor Tom. A tourism guide in Belize since 1992, specializing in cave tubing, zip line, Mayan sites, fishing and snorkeling tours in Belize"
                                src = {logoImg}/>
                        </a>
                    </div>
                    </div>
                <div>
                    <div>
                        <TripadvisorWidget type="11"></TripadvisorWidget>
                    </div>
                </div>
                <BottomBarLogoMenu followUs={this.props.followUs} bottomBarGridItems={this.props.bottomBarGridItems}/> 
            </nav>
        );
    }
}
export default Footer;