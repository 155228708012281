import React from 'react'; 
import AboutText from './AboutText.js'; 

function AboutGrid() {
    return (
        <div className="aboutGrid">
            <AboutText/> 
        </div>
    );
}

export default AboutGrid;
