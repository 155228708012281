import React from 'react'; 
import './../App.css';
import '../Styles/About.css'; 

const titleText = "Cave Tubing Covid Information";

const questions = [
];

function Covid() {
    return (
        <div className="tourBody">
                <h1> {titleText} </h1>
                {questions.map(question => 
                    <div className="questionBox">
                        <div className="question"> {question.question}</div> 
                        <div className="answer"> {question.answer}</div> 
                    </div> 
                )}
        </div>
    );
}

export default Covid;