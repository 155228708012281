import React from 'react'; 
import './../App.css';

import ContactForm from './ContactForm.js'; 
import '../Styles/Contact.css'; 

const contactFields = [
    { type: "text", title: "First Name", required: true, placeholder: "Eiluv", id: "" },
    { type: "text", title: "Last Name", required: true, placeholder: "Belize", id: "" },
    { type: "text", title: "Email Address", required: true, placeholder: "jdoe@example.com", id: "" },
]; 


function Contact({tours}) {

    return (
        <div className="contactBody">
            <h1>Contact Cave Tubing Belize</h1>
            <ContactForm contactFields={contactFields} tours={tours}/> 
        </div>
    );
}

export default Contact;
