import React from 'react'; 

function ContactForm({ contactFields, tours }) {

    return (
        <div className="contactForm">
        </div>
    );
}

export default ContactForm;
