import React from 'react'; 

function IslandImg({small}) {
    return (
        <div className={small ? "cruiseContainer islandCruiseContainer smallCruiseContainer" : "cruiseContainer islandCruiseContainer"}>
            <div className="mayanUpperDeck islandUpperDeck">
                <div className="hotelPort"></div>
            </div>
            <div className={ small ? "island small" : "island" }></div>
            <div className = "waterLine"></div>
            <div className={ small ? "waterLines small" : "waterLines"}>
                <div>
                    <span></span>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            { small ? null : 
            <div className="cruiseWheel islandCruiseWheel">
                <div className="cruiseWheelInner">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="cruiseWheelSunFace">
                        <div className="sunFaceLeftCheek">
                            <div className="leftNostril"></div>
                        </div>
                        <div className="sunFaceRightCheek">
                            <div className="rightNostril"></div>
                        </div>
                        <div className="kylieJennerLips"></div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default IslandImg;