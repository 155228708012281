import React from 'react'; 
import PropTypes from 'prop-types';

function DesktopNavbarText({ text}) {
    return (
        <div className="desktopNavbarText">
            {text}
        </div>
    );
}

DesktopNavbarText.propTypes = {
    text: PropTypes.string
}

DesktopNavbarText.defaultProps = {
    text: ""
}

export default DesktopNavbarText;