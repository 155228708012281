import React from 'react';


/* Name:     ImageSlideDeck (class component)
 * Purpose:  Displays images which change with arrow keys and words overlaying images
 * Props:    HashTable with key: imageName {should be tour name + x .jpg}
 *                          value: imageSource
 * 
 * 
 * Notes:    The image names should always match the exact tour name field (found in app.js)
 *           Only .jpg image extensions will be taken in (not even jpeg). All other image 
 *           extensions will be ignored. 
 */ 
class ImageSlideDeck extends React.Component {

    constructor(props) {
        super();
        this.images = props.images;
        this.indexKeys = []; 
        for (let i = 0; i < this.images["numKeys"]; ++i) {
            this.indexKeys.push(this.images["name"] + (i+1).toString() + ".jpg");
        }

        this.state = {
            currIndex: 0, 
            windowHeight: window.innerWidth
        }; 
        if(props.alts){
            this.alts=props.alts; 
        }
        else this.alts = Array(this.indexKeys.length).fill(''); 
    }

    componentDidMount() {
        window.addEventListener('resize', () => { this.setState({ windowHeight: window.innerWidth });});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => { this.setState({ windowHeight: window.innerWidth }) });
    }

    changeSlides(delta) {
        let temp = ((this.state.currIndex + delta) % this.indexKeys.length); 
        if (temp < 0) temp = this.indexKeys.length + temp; 
        this.setState(state => ({ currIndex: temp, windowHeight: window.innerWidth }));
    }

    render() {
        return (
            <div className= {this.state.windowHeight > 768 ? "slideShowContainer" : "slideShowContainer lazy"}
                style={this.state.windowHeight > 768 ? null : 
                    { backgroundImage: `url(${this.props.images[this.indexKeys[this.state.currIndex]].default})` }}>
                {
                    this.indexKeys.length > 1 ? 
                        <div> 
                            <span className="next" onClick={()=>(this.changeSlides(1))}/>
                            <span className="prev" onClick={() => (this.changeSlides(-1))} /> 
                        </div> 
                    : null 
                }
                {
                    <img className="slideImage"
                        src={this.images[this.indexKeys[this.state.currIndex]]}
                        // alt={this.alts[this.state.currIndex]}
                        loading = {this.state.currIndex !==0 ? "lazy" : "eager"}>
                    </img>
                }
            </div>);
    }
}

export default ImageSlideDeck;
