import React from 'react'; 

function CruiseImg({small}) {
    return (
        <div className={small ? "cruiseContainer smallCruiseContainer bundleLogoItem": "cruiseContainer"}>
            <div className={small ? "cruiseUpperDeck cruiseUpperDeckSmall" : "cruiseUpperDeck"}></div>
            <div className={small ? "cruiseMiddleDeck cruiseMiddleDeckSmall" : "cruiseMiddleDeck"}></div>
            <div className={small ? "cruiseLowerDeck cruiseLowerDeckSmall" : "cruiseLowerDeck"}></div>
            { small ? null : 
                <div className="cruiseWheel">
                    <div className="cruiseWheelInner">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="cruiseWheelSunFace">
                            <div className="sunFaceLeftCheek">
                                <div className="leftNostril"></div>
                            </div>
                            <div className="sunFaceRightCheek">
                                <div className="rightNostril"></div>
                            </div>
                            <div className="kylieJennerLips"></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default CruiseImg;