import React from 'react';
import Flex from './flex';

function AboutText() {
    return (
        <div className="aboutText">
            <h1 className="aboutTitle">Our guides have the expertise to provide a fun and
            educational cave tubing tour
            </h1>
            <p>
                    Major Tom, Minor Tom and their guides are your doorway to this jewel called Belize. The Greenwood family and their guides will be your hosts as you visit the awesome Caves Branch System -- the most popular tour in Belize for cave tubing and rainforest exploration.
            </p>
            <h1 className="aboutTitle">
                The Best Guides Belize Offers
            </h1>
            <p>
                    We are veterans of this tour, having started operating this attraction in 1992, 
                    giving us many years of experience and exposure to this cave system.
                    Float the path of the Maya shamans through their world, 
                    known as Xibalba (shi-bal-bah), in comfort and safety, 
                    with our modern equipment. From cruise ship passengers to overnight visitors,
                    we will bring you the holiday experience you are looking for, and more.
                    The Greenwood family have been operating this cave tubing attraction since 1992
                    and have many years of experience with this amazing and extensive 
                    cave system and the rainforest that surrounds it. 
                    Major Tom, Minor Tom and and their veteran guides in Belize will take good care
                    of you and your families and friends.
            </p> 
            <p>
                    Minor Tom's  famous dad, the Major has a long history in Belize as a Justice 
                    of the Peace, a Commissioner of the Supreme Court, 
                    former president of the National Tour Guide Association, 
                    the Tour Operator's Association, and presently, 
                    President of the Federation of Cruise Tourism Associations of Belize place
                    him as a well-respected leader in the tourism industry.
            </p>
            <p>
                Along with serving 17 years in the military in Belize, 
                the Major also served as City Councilor for three years during his entry 
                into politics in Belize, from 1989 to 1992.
            </p>
            <p>
                The first ever organized book on tour guiding produced by the Belize Tourism Board
                saw Minor Tom's dad as one of its contributors. He is considered one of Belize's
                most senior tour guides, having gained a wealth of information and experience,
                which he now passes on to his guests.
            </p>
            <p>
                Whatever tour activity you require, Major Tom can supply Belize Mayan sites,
                wildlife centers, horseback riding, cave tubing and ziplining, 
                river and sea fishing tours, snorkeling, hotel and local airline bookings, 
                you name it. We are destination managers who have been in tourism for the past 21 
                years, and have maintained business relationships with tourism suppliers all over
                Belize.
            </p> 
            <div className="white"> 
                Major Tom's credentials include: 
                <ol>
                    <li> The National Tour Guides Association of Belize</li> 
                    <li> President of The Federation of Cruise Tourism Associations of Belize</li>
                    <li> Past President of Belize National Tour Operator's Association </li> 
                    <li> Past President, Belize National Tour Guide's Association </li> 
                </ol> 
            </div> 
            <p> 
                
                Cave tubing in Belize is truly an experience that will instill lasting memories 
                of spectacular scenery and overall relaxation in an atmosphere of utter
                tranquility.
            </p>
            <div className="padded-top"></div>
            <Flex/>
            <div className="bottomMargin"></div>
        </div>
    );
}

export default AboutText;