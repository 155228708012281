import React from 'react';

import DesktopNavbar from './DesktopNavbar.js';
import MobileNavbar from './MobileNavbar.js';
import Footer from './Footer.js';

// Common Components
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Possibly delete
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Custom Components
import '../App.css';
import BookNow from '../Components/BookNow.js';
import BundleTours from '../Components/BundleTours.js'; 
import CruiseImg from '../Components/CruiseImg.js';
import HotelImg from '../Components/HotelImg.js'; 
import IslandImg from '../Components/IslandImg.js'; 
import TransportationOptions from '../Components/TransportationOptions.js'; 
import TourGrid from '../Components/TourGrid.js'; 
import MoreToursBtn from '../Components/MoreToursBtn.js'; 

// GridOptionItem images
import sanPedro from '../images/San_Pedro_Beach.webp';
import belizeCity from '../images/hotel.webp';
import cruiseImage from '../images/cruise.webp';

import majorTom from '../images/home/majorTom.webp';

// Slide Images
import homeImg1 from '../images/home/home1.webp';
import homeImg1_1440 from '../images/home/home1_1440.webp';
import homeImg1_1024 from '../images/home/home1_1024.webp';
import homeImg1_768 from '../images/home/home1_768.webp';
import homeImg1_428 from '../images/home/home1_428.webp';

import homeImg2 from '../images/home/home2.webp';
import homeImg2_1024 from '../images/home/home2_1024.webp';
import homeImg2_768 from '../images/home/home2_768.webp';

import homeImg3 from '../images/home/home3.webp';
import homeImg3_768 from '../images/home/home3_768.webp'
import homeImg3_428 from '../images/home/home3_428.webp'

import homeImg4 from '../images/home/home4.webp';
import homeImg4_1024 from '../images/home/home4_1024.webp';
import homeImg4_768 from '../images/home/home4_768.webp';

import homeImg5 from '../images/home/home5.webp';
import homeImg5_768 from '../images/home/home5_768.webp';
import homeImg5_428 from '../images/home/home5_428.webp';

import homeImg6 from '../images/home/home6.webp';
import homeImg6_768 from '../images/home/home6_768.webp';
import homeImg6_428 from '../images/home/home6_428.webp';

import cavePhoto_largest from '../images/home/cavePhoto_largest.webp'

//  import { FullPage, Slide } from 'react-full-page';
import ReactFullpage from '@fullpage/react-fullpage';
import TripadvisorWidget from './TripadvisorWidget.js';
import Flex from './flex.js';

// Hard code the alts for home slide images
const homeAlt1 = "Safe Family Fun Cave Tubing with Major Tom from Belize City";
const homeAlt2 = "Visit Xunantunich and other exciting Mayan Ruins";
const homeAlt3 = "Beautiful Jungle Scenery in the unforgettable cave tubing experience";
const homeAlt4 = "Visit Altun Ha Mayan Ruins and the famous Jade Skull";
const homeAlt5 = "Bring friends and family and tube through serene jungle into ancient ceremonial Mayan Caves";
const homeAlt6 = "The beautiful jungle river you will tube through on your way to the Caves";


const transportationOptions = [
    {
        backgroundImage: cruiseImage,
        embarkmentValue: 0,
        url: "/tours/AllTours",
        title: "Cruise Tender to Belize City",
        value: "hotel",
        imgComponent: CruiseImg,
        shortDescription: "Catching a morning cruise tender to Belize City while your cruise line docks for a day?",
        description: [
            "We accomodate cruise passenger guests and will bring you the holiday experience you are looking for, and more!",
            "Once your cruise docks, our guides will be waiting outside of your tender through TERMINAL ONE with a signboard reading MAJOR TOM AND MINOR TOM, CAVE TUBING to take you to your adventure. ",
            "You will have plenty of time to see the sites before we return you to your tender. ",
            "We are exceptionally well-informed on cruise logistics as Major Tom is presently the President of the Federation of Cruise Tourism Associations of Belize. In twelve years of providing adventures to cruise passengers, we have yet to be late for tenders back to the ship."]
    },
    {
        backgroundImage: belizeCity,
        embarkmentValue: 2,
        url: "/tours/AllTours",
        title: "Staying in Belize City",
        value: "hotel",
        imgComponent: HotelImg,
        shortDescription: "Staying late or overnight in the Belize City area?",
        description: ["Staying in Belize? If you are arriving by air, we can meet you at the airport and offer transfers to hotels, local airlines, or water taxis.",
            "There will be as much time as you need to relax and stop at whatever sites and attractions take your fancy along the journey!",
            "We will take you to anything that crosses your mind, and drive you back to where you are staying when your journey is over."]
    },
    {
        backgroundImage: sanPedro,
        embarkmentValue: 1,
        url: "/tours/AllTours",
        title: "San Pedro & Caye Caulker Ferry to Belize City",
        imgComponent: IslandImg,
        shortDescription: "Catching the morning ferry to Belize City from the San Pedro or Caye Caulker?",
        description: ["We will meet guests from the Cayes such as San Pedro and Caye Caulker.",
            "The Caye Caulker Water Taxi Association has boats every hour starting at 6:30am from Caye Caulker and San Pedro to Belize City.", 
            "Please purchase your water taxi tickets in advance to ensure the most time for your tour.",
            "Our guides will be waiting outside of the water taxi terminal with a signboard reading MAJOR TOM AND MINOR TOM, CAVE TUBING to take you on your adventure.",
            "You will have plenty of time to see the sites before we return you to the water taxi port."]
        }
]; 

//const introText = "Placencia Action Tours is your number one choice in tour operations when visiting the Placencia peninsula.We offer tours in the southern region of Belize and with sister companies all across the country we are the most viable choice for reaching any and all of your vacation goals while visiting Beautiful Belize."
const introText = ["If you are looking for a Belize Holiday you won't forget, you have come to the right place! We take both cruise ship passengers or those arriving in Belize by land or air, to stay for a few days or longer. Major Tom, Minor Tom, and their fine crew will bring you the holiday experience you are looking for, and much more!",
    "Major Tom and Tom Jr. - making your vacation in Belize a great adventure!",
    "If arriving by air, we can meet you at the airport and offer transfers to hotels, local airlines, or water taxis.",
    "Our tours are available to guests staying in Belize City, guests arriving in Belize City by Cruise, and guests staying on the Cayes who plan on taking the Caye Caulker Water Taxi Service to Belize City."
];

const tourText = ["Tours are in air-conditioned cars, vans, or buses, and include leisurely drives through the beautiful Belize countryside. Attractions are between 40 minutes to an hour away from Belize City. Mayan ruins, wildlife centers, riverboat wildlife tours, tropical primary broadleaf rainforest, and nature trails, you name it and we will take you there!",
    "And remember - for those planning extended holidays in Belize, Major Tom, Tom Jr. and their professional guides are destination managers since 1986 - in country and international airline bookings, local water taxi transfers, meet-and-greet service at the airport, hotel/lodge reservations, tour packages including any of the distinations in Belize as well as Mayan sites in Mexico and Guatemala. Choose Belize for your extended stay and go for land destinations and fabulous island holidays. Ask about our HEART OF THE MAYA package.", 
    "Come cave tubing with Major Tom, Minor Tom, and their veteran guides!",
]; 

const bundleText = ["Extend your tour adventure and get fantastic savings by adding on some additional tours! Your professional guides will take you to multiple locations in the day so that you can make the most out of your time here.",
"Be sure to filter on where you are residing so that we can make sure you have as much time as possible to see the wonders of Belize."
];

const anchors = [
    "slides",
    "embarkment",
    "tours",
    "tourBundles",
    "touringWithMajorTom",
    "moreInfo"
]

const navigationTooltips = [
    "Slide Show",
    "Embarkment Options",
    "Tours",
    "Tour Bundles",
    "Touring with Major Tom",
    "More Info"
];

class Home extends React.Component {

    constructor(props) {
        super();
        props.tours.sort((a, b) => { return a.name === "caveTubing" ? -1 : b.name === "caveTubing" ? 1 : a.name === b.name ? 0 : a.name < b.name ? -1 : 1 }); 
        this.allTours = props.tours; 
        this.state = {
            tours: props.tours.slice(0, 3),
            bookingToggled: false,
            bookingBundle: null,
            windowWidth: window.innerWidth,
            dirtyPage: false,
        }
        this.addTours = this.addTours.bind(this);
        this.toggleBooking = this.toggleBooking.bind(this);

        // Hackiest way I could quickly think of to allow me to append a click function that affects home component's state rather than app's
        this.siteUrls = props.siteUrls;
        let bookNowUrlIdx = -1;
        for(let i = 0; i < this.siteUrls.length; ++i) {
            this.siteUrls[i].hasClickFunction = false;
            if (this.siteUrls[i].key === "booknow") {
                bookNowUrlIdx = i;
            }
        }
        if (bookNowUrlIdx >= 0) {
            this.siteUrls[bookNowUrlIdx].hasClickFunction = true;
            this.siteUrls[bookNowUrlIdx].clickFunction = this.toggleBooking;
        }
    }

    toggleBooking(bundle){
        let temp = this.state.bookingToggled;
        this.setState({bookingToggled: !temp});
        this.setState({bookingBundle: bundle});
    }

    addTours() {
        if (this.state.tours === this.allTours) {
            this.setState({tours: this.props.tours.slice(0,3)});
        } else this.setState({ tours: this.allTours });
    }

    render() {
        return (
            <>
            <div className = {!this.state.bookingToggled ? "homeContainer" : "homeContainer toggled"}>
               
                <ReactFullpage
                    navigationTooltips={navigationTooltips}
                    anchors={anchors}
                    navigation
                    normalScrollElements = ".MuiDialogContent-root"
                    // selectionSelector = {`.custom-section`}
                    licenseKey = "8Y8KK-R1436-02K0J-78JF8-IPJYR"
                    // options = {{
                    //     fixedElements: '#mobileNavbar, .mobileNavbar'
                    // }}
                    render={({state, fullpageApi }) => {

                        // If the booking dialog is open don't allow scrolling at all 
                        if(fullpageApi && this.state.bookingToggled) {
                            fullpageApi.setAllowScrolling(false);
                            fullpageApi.setKeyboardScrolling(false);
                        }
                        else if(fullpageApi) {
                            fullpageApi.setAllowScrolling(true);
                            fullpageApi.setKeyboardScrolling(true);
                        }
                        // if(fullpageApi) {
                        //     if(this.state.bookingToggled) {
                        //         fullpageApi.setAllowScrolling(false);
                        //     }
                        //     else {
                        //         fullpageApi.setAllowScrolling(true);
                        //     }
                        // }
                            // if(this.state.dirtyPage && fullpageApi) {
                            //     console.log("dirty page", this.state);
                            //     
                            //   
                            //     this.setState({dirtyPage: false});
                            // }
                            // else console.log("clean page", this.state)
                      return (
                        <>
                        {/* <MobileNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} embarkment = {this.props.embarkment}
                        embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/> */}
                        <ReactFullpage.Wrapper>
                        {this.state.bookingToggled ? 
                            <Dialog open = {this.state.bookingToggled}
                                    scroll = {'paper'}
                                    maxWidth = {'xl'}
                                    fullWidth = {true}>
                                <DialogActions>
                                    <Button onClick={()=>{this.setState({bookingToggled: false})}}>Close</Button>
                                </DialogActions>
                                <DialogTitle>Reserve Your Adventure</DialogTitle>
                                <DialogContent>
                                    <BookNow initialEmbarkment = {this.props.embarkment}
                                    selectedTours = {this.state.bookingBundle}
                                    closeFunction = {() => {this.toggleBooking();}}
                                    iframe = {true} bundles = {this.props.bundles}
                                    tours = {this.props.tours}/>
                                </DialogContent>
                            </Dialog>
                        :
                        <></>}
                          <div className="section no-overflow">
                              <MobileNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} embarkment = {this.props.embarkment}
                                    embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                              <DesktopNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} sticky={true} embarkment = {this.props.embarkment} 
                                    tooltips = {this.props.tooltips}
                                    embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                                <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${cavePhoto_largest}`}
                                            alt = {homeAlt1}/>
                                    </div>
                                </div>
                                {/* <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${homeImg1} 1762w, ${homeImg1_1440} 1440w, ${homeImg1_1024} 1024w, ${homeImg1_768} 768w, ${homeImg1_428} 428w`}
                                            alt = {homeAlt1}/>
                                    </div>
                                </div> */}
                                <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${homeImg2}, ${homeImg2_1024} 1024w, ${homeImg2_768} 728w`} loading = "lazy" alt = {homeAlt2}/>
                                    </div>
                                </div>
                                <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${homeImg3}, ${homeImg3_768} 768w, ${homeImg3_428} 428w`} loading = "lazy" alt = {homeAlt3}/>
                                    </div>
                                </div>
                                <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${homeImg4}, ${homeImg4_1024} 1024w, ${homeImg4_768} 768w`} loading = "lazy" alt = {homeAlt4}/>
                                    </div>
                                </div>
                                <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${homeImg5}, ${homeImg5_768} 768w, ${homeImg5_428} 428w`} loading = "lazy" alt = {homeAlt5}/>
                                    </div>
                                </div>
                                <div className="slide dark">
                                    <div className = "slideImgContainer">
                                        <img srcSet = {`${homeImg6}, ${homeImg6_768} 768w, ${homeImg6_428} 428w`} loading = "lazy" alt = {homeAlt6}/>
                                    </div>
                                </div>
                                <Accordion defaultExpanded = {true} className="accordion bottomAccordion">
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header">
                                      <h1>
                                          Belize Cave Tubing Tours
                                      </h1>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="introText flexed col">{introText.map((textBlock, idx) =>
                                                    (<p className="homeTextBlock" key={idx}>{textBlock}</p>))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                          </div>
                          <div className="section singleScreen">
                            <MobileNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} embarkment = {this.props.embarkment}
                                embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                            <DesktopNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} sticky={true} embarkment = {this.props.embarkment} 
                                tooltips = {this.props.tooltips}
                                embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                            <div className = "homePanel fullScreen flexed">
                              <TransportationOptions embarkment = {this.props.embarkment}
                                    tooltips = {this.props.tooltips}
                                    embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}
                                    transportationOptions={transportationOptions} />
                            </div>
                          </div>
                          <div className="section">
                            <MobileNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} embarkment = {this.props.embarkment}
                                embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                            <DesktopNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} sticky={true} embarkment = {this.props.embarkment} 
                                tooltips = {this.props.tooltips}
                                embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                            <div className = "homePanel fullScreen">
                                <TourGrid tours={this.state.tours}
                                    embarkment = {this.props.embarkment}
                                    bookTourFunction = {this.toggleBooking}
                                    reducedHeight={this.state.tours.length <= 3}/>
                                <MoreToursBtn clickFunction={this.addTours}/>
                            </div>
                          </div>

                          {/* Bundles */}
                          <div className="section">
                              <MobileNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} embarkment = {this.props.embarkment}
                                    embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                              <DesktopNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} sticky={true} embarkment = {this.props.embarkment} 
                                    tooltips = {this.props.tooltips}
                                    embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                              <div className = "homePanel noMargin fullScreen invertArrows">
                                  <BundleTours clickBundleFunction = {this.toggleBooking}
                                    embarkment = {this.props.embarkment}
                                    tours = {this.allTours}
                                    bundles = {this.props.bundles}
                                    bundleText = {bundleText}
                                    bundleTitle = "Bundle Tours and Save!"/>
                              </div>
                          </div>
                          <div className = "section">
                            <MobileNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} embarkment = {this.props.embarkment}
                                embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                            <DesktopNavbar fixed={true} siteUrls={this.siteUrls} tours={this.props.tours} sticky={true} embarkment = {this.props.embarkment} 
                                tooltips = {this.props.tooltips}
                                embarkmentChange = {(val) => {this.props.embarkmentChanged(val);}}/>
                            <div className = "homePanel fullScreen">
                                <div className = "padded-top flexed col">
                                    <div className = "flexed row gap">
                                        <div className= "flexed col gap">
                                          <h2>
                                              Touring with Major Tom
                                          </h2>
                                          <div className="introText flexed col">{tourText.map((textBlock, idx) =>
                                            (<p className="homeTextBlock" key={idx}>{textBlock}</p>))}
                                          </div>
                                        </div>
                                        <div>
                                            <img src={majorTom} loading="lazy" alt="Cave Tubing's founder Major Tom enjoying the river"></img>
                                        </div>
                                    </div>
                                    <Flex/>
                                </div>
                            </div>
                          </div>
                          <div className = "section">
                              <Footer fullScreen = {true} followUs={this.props.followUs} bottomBarGridItems={this.siteUrls} />
                          </div>
                        </ReactFullpage.Wrapper></>
                      );
                    }}
                  />
            </div>
            </>
            );
    }
}
export default Home;