import React from 'react';
import DesktopNavbarText from './DesktopNavbarText.js';
import DesktopNavbarBottom from './DesktopNavbarBottom.js';
import logoImg from './../images/cave_tubing.png';
import ReactTooltip from 'react-tooltip';

function DesktopNavbar({ tooltips, fixed, siteUrls, tours, sticky, embarkment, embarkmentChange}) {
    return (
        <div className={fixed ? "desktopNavbar fixed" : "desktopNavbar"}>
            {fixed ?
                <div className="desktopNavbarLogoSpace">
                    <a href = "/">
                        <img className="logoImg"
                            alt="Enjoy a Belize cave tubing tour with Major Tom and Minor Tom. A tourism guide in Belize since 1992, specializing in cave tubing, zip line, Mayan sites, fishing and snorkeling tours in Belize"
                            src = {logoImg}/>
                    </a>
                </div>
                : <></>
            }
            <DesktopNavbarText text="Cave Tubing Belize" /> 
            <DesktopNavbarBottom tooltips={tooltips}
                siteUrls={siteUrls} tours = { tours } sticky = {sticky} embarkment = {embarkment} embarkmentChange = {embarkmentChange} /> 
        </div>
    );
}

export default DesktopNavbar; 
/*            <div className="desktopNavgarLogoSpace">
<a target="_blank" href="http://www.tripadvisor.com/">
    <img src="http://www.tripadvisor.com/img/cdsi/img2/awards/CoE2014_WidgetAsset-14348-2.png"
        alt="Trip Advisor Certificate of Excellence"
        class="widCOEImg"
        id="CDSWIDCOELOGO" />
</a>
            </div > */ 