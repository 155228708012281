import React from 'react'; 

function FollowUsList({ followUs }) {
    return (
        <div className="followUsList">
            <div className="followUsTitle"> Follow us on: </div> 
            {
                followUs.map((icon, index) => (
                    <a key={index} href={icon.url}>
                        {icon.imgComponent()}
                    </a>))
            }
        </div>
    );
}

export default FollowUsList;