import React from 'react';


class XIcon extends React.Component {

    constructor(props) {
        super();
    }

    render() {
        return (
            <div className="XIcon"> 
                <div className="leftLine"></div>
                <div className="rightLine"></div> 
            </div>
        );
    }
}

export default XIcon;