import React from 'react'; 

function TripadvisorIcon() {
    return (
        <div className ="facebookIcon">
            <div className="TripadvisorIcon">
                <div className="owlHead">
                    <div className="owlTriangle">
                    </div>
                    <div className="owlTop">
                    </div>
                    <div className="owlEye">
                        <div className="owlPupil">
                        </div>
                    </div>
                    <div className="owlEye">
                        <div className="owlPupil">
                        </div>
                    </div>
                </div>
                </div> 
            </div> 
    );
}

export default TripadvisorIcon;