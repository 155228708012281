// Common Components
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import { LocationCity } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';
import {GiIsland} from 'react-icons/gi'

// Custom Components
import GridOptionItem from './GridOptionItem.js'; 

function TransportationOptions({transportationOptions, tooltips, embarkment, embarkmentChange})
{
    return (
        <div className = "transportationOptionsContainer">
            <div className = "fpTitle padded-top-bottom">
                <h2 className="embarkmentTitle">
                    How are you Getting to Belize City?
                    <span>
                        {embarkment === 0 ?
                            <span className = "transportationOptionIcon">
                                <DirectionsBoatFilledIcon data-tip = {tooltips.cruiseTooltip} fontSize = "large"></DirectionsBoatFilledIcon>
                            </span>
                        : embarkment === 1 ?
                            <span className = "transportationOptionIcon">
                                <GiIsland data-tip = {tooltips.cayeTooltip}/>
                            </span>
                        : embarkment === 2 ?
                            <span className = "transportationOptionIcon">
                                <LocationCity data-tip = {tooltips.hotelTooltip} fontSize = "large"></LocationCity>
                            </span>
                        : <></>
                        }
                    </span>
                    <ReactTooltip className="tooltip"/>
                </h2>
            </div>
            <div className="optionsGrid">
                {
                    transportationOptions.map(option => (
                        <GridOptionItem embarkment = {embarkment}
                            embarkmentChange = {embarkmentChange}
                            option = {option}/> 
                    ))
                }
            </div>
        </div>
    );
}

export default TransportationOptions;