//Styles
import './../App.css';
import '../Styles/ThankYou.css';

//Libraries, plugins 
import React from 'react';              //Use of class-based react components

const faqElement = <div>Check out our <a className="prettyLink" href="/FAQ">FAQ</a> and <a className="prettyLink" href="/safety"> safety guidelines</a> prepare so that you can make the most of your time.</div>; 
const contactElement = <div>If you have any questions about your tour reservation, please don't hesitate to <a href="/Contact" className="prettyLink">contact us</a> and we will get back to you as soon as we can!</div >;
const thankYouText = [
    "Thank you very much for your payment! Your transaction has been completed.",
    "You should receive a confirmation email at the address specified.",
    "Please save a copy of your confirmation email if you can. We look forward to seeing you",
    faqElement,
    contactElement
];

class ThankYou extends React.Component {

    constructor(props) {
        super(); 
    }

    render() {
        return (
            <div className="thankYouContainer">
                <div className="containerText">
                    <h1>Your Adventure is Booked!</h1>
                    {thankYouText.map(textRow => (
                        <div className="textRow">{textRow}</div> 
                    ))}
                </div> 
            </div>
        );
    }
}

export default ThankYou; 