import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Button } from '@material-ui/core';
import SellIcon from '@mui/icons-material/Sell';
import GroupsIcon from '@mui/icons-material/Groups';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

class PricingBox extends React.Component {

    constructor(props) {
        super();
    }

    render() {

        return (
            // <div className="pricingBox" onClick = {(event) => 
            //     {if(this.props.bookTourFunction != null && this.props.name != null )
            //     {
            //         this.props.bookTourFunction([this.props.name]); 
            //     }}}>
            //     <h3 className="OptionTitle">Pricing and Logistics</h3> 
            //     <div>This tour can be booked by guests residing in a hotel in Belize City, guests arriving by cruise ship, and guests residing in islands accessible by the Caye Caulker Water Taxi.</div>
            //     <div>This tours costs ${this.props.price} USD per adult. This can also be payed as ${this.props.price * 2} BZE per adult.</div> 
            //     <div>Children under 5 are free!</div>
            //     <div>Book your spots for this tour now to pay a 10% booking deposit of ${this.props.price * .1} USD/adult and pay the rest to your guide when the tour begins. Booking deposits are paid through a secure PayPal portal.</div> 
            //     <div>Please do not hesitate to contact us about rates for large groups.</div>
            //     <div className = "imageCardBtn">Book This Tour</div>
            // </div>
            <Accordion defaultExpanded = {true} expanded = {true} className="logisticsGridItem accordion noMargin">
            <AccordionSummary>
                <div className = "flexed row">
                    <div>
                        Pricing and Logistics
                    </div>
                    <div>
                        <SellIcon/>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className = "flexed col">
                    <div>
                        This tours costs ${this.props.price} USD per adult. This can also be payed as ${this.props.price * 2} BZE per adult.
                    </div>
                    <div className = "flexed row">
                        <div>
                            <ChildCareIcon/>
                        </div>
                        <div>
                            Children under 5 are free!
                        </div>
                    </div>
                    <div className = "flexed row">
                        <div>
                            <ConfirmationNumberIcon/>
                        </div>
                        <div>
                            Book your spots for this tour now to pay a 10% booking deposit of ${this.props.price * .1} USD/adult and pay the rest to your guide when the tour begins. Booking deposits are paid through a secure PayPal portal.
                        </div>
                    </div>
                    <div className = "flexed row">
                        <div>
                            <GroupsIcon/>
                        </div>
                        <div>
                            Please do not hesitate to contact us about rates for large groups.
                        </div>
                    </div>
                    <Button variant="contained"
                        className = "loadMoreToursBtn yellow"
                        onClick = {(event) => {
                            if(this.props.bookTourFunction !== null && this.props.name !== null) {
                                this.props.bookTourFunction([this.props.name]);
                            }
                        }}>
                        Book This Now
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
        );
    }
}

//If we want to re-enable child-pricing for this site just uncomment this and add it back into the jsx above!
//<div>This tours costs ${this.props.priceChild} USD per child ages 5-11. This can also be payed as ${this.props.priceChild * 2} BZE per child.</div> 


export default PricingBox;