import React from 'react'; 

function BottomBarGrid({ bottomBarGridItems }) {
    return (
        <div className="bottomBarLogoGrid">
            {
                bottomBarGridItems.map((item, index) => (
                    <a key = {item.key} href = {item.url} className="iconTextContainer">
                        {item.icon}
                        <span className="iconText">{item.text}</span>
                    </a>))
            }
        </div>
    );
}

export default BottomBarGrid;