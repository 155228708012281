import React, {useState} from 'react';   //Use of class-based react components
import { Button } from '@material-ui/core';

import InfoIcon from '@mui/icons-material/Info';

// import './../App.css';

function TourCardBundleItem ({index, fullTour}) {

    // Each tour card has their own full description text
    const [showText, toggleText] = useState(false);

    const doToggle = () => {
        let toggle = showText;
        toggleText(!toggle);
    }

    return (
        <div className="tourCardBundleItem"
             key = {index}>
            <div className="tourCardBundle"
                 style={{backgroundImage: `url(${fullTour.cardImage})`}}>
                <div className="tourBundleGridLink">
                    <h3>
                        {fullTour.text}
                    </h3>
                </div>
                <div className = {showText ? "fullTourDescText" : "fullTourDescText hidden"}>
                    {fullTour.content.map((item, index) => (
                        <div key = {index}>{item}</div>
                    ))}
                </div>
                <div className = "buttonBottomContainer">
                <Button variant="contained"
                            className = "loadMoreToursBtn"
                            onClick = {doToggle}
                            size = "large">
                            {showText ? "Hide Description" : "Show Description"}
                        </Button>
                        <a href = {fullTour.url}>
                            <Button variant="contained"
                                className = "loadMoreToursBtn"
                                size = "large">
                                See Tour Page
                            </Button>
                        </a>
                </div>
            </div>
        </div>
    );
} export default TourCardBundleItem;