import React from 'react';

import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import { LocationCity } from '@material-ui/icons';
import {GiIsland} from 'react-icons/gi'
import ReactTooltip from 'react-tooltip';

function DesktopNavbarBottom({ tooltips, siteUrls, tours, sticky, embarkment, embarkmentChange }) {
    return (
        <div className={ sticky === true ? "desktopNavbarBottom scroll":"desktopNavbarBottom"}>
            <div className="desktopMenuContainer">
                <div className = "siteUrls">
                {siteUrls.map(page => (
                    page.id === "desktopLand" ?
                        <a href={page.url}>
                            <div className={"desktopMenuOption " + (page.id ? "desktopDropdown" : "")}>
                                <div>
                                    <a href={page.url}> {page.text} </a>
                                    <div id={page.id}>
                                        {tours.map(tour => (
                                            <a href={tour.url} class="dropdownMenuOption">
                                                <div className="desktopNavbarTourStripe"></div>
                                                {tour.text}
                                            </a>))}
                                    </div>
                                </div>
                            </div>
                        </a>
                        :
                        page.id === "desktopCruise" ?
                            <a href={page.url}>
                                <div className={"desktopMenuOption " + (page.id ? "desktopDropdown" : "")}>
                                    <div> 
                                        <a href={page.url}> {page.text}</a>
                                    <div id={page.id}> 
                                        {
                                            tours.filter(tour => tour.cruise === true).map(filteredTour => (
                                                <a href={filteredTour.url} class="dropdownMenuOption">
                                                    <div className="desktopNavbarTourStripe"></div> 
                                                    {filteredTour.text}
                                                </a>
                                            ))
                                        }
                                        </div>
                                    </div>
                                </div> 
                            </a>
                        :
                        page.hasClickFunction ?
                            <div className="desktopMenuLink"
                                onClick = {() => {page.clickFunction()}}>
                                {page.text}
                                <div className="desktopNavbarStripe"></div>
                            </div>
                        : page.url && page.key ?
                        <a className="desktopMenuLink" href={page.url}>
                            {page.text}
                            <div className="desktopNavbarStripe"></div>
                        </a>
                        : <></>
                ))}
                </div>
                { sticky === true && embarkment !== null && embarkmentChange ?
                    <div>
                        <ReactTooltip className="tooltip"
                            offset = "{'bottom': 30}"/>
                        {/* <a href = "#embarkment" class="changeEmbarkmentLabel">Change Embarkment</a> */}
                    <div className = "changeEmbarkmentContainer">
                        <a href = "#embarkment" class="changeEmbarkmentLabel" data-tip = "View Details">Change Embarkment</a>


                    <div className = "flexed flexMax">
                            <DirectionsBoatFilledIcon className={embarkment === 0 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
                                data-tip = {tooltips && tooltips.cruiseTooltip ? tooltips.cruiseTooltip : null}
                                onClick = {() => {embarkmentChange(0)}}/>
                            <LocationCity className={embarkment === 2 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
                                data-tip = {tooltips && tooltips.hotelTooltip ? tooltips.hotelTooltip : null}
                                onClick = {() => {embarkmentChange(2)}}></LocationCity>
                            <GiIsland className={embarkment === 1 ? "changeEmbarkmentIcon selected" : "changeEmbarkmentIcon"}
                                data-tip = {tooltips && tooltips.cayeTooltip !== null ? tooltips.cayeTooltip : null}
                                onClick = {() => {embarkmentChange(1)}}/>
                    </div>
                    </div>
                    </div>
                : <></>}
            </div>

        </div>
    );
}

export default DesktopNavbarBottom;