
import React from 'react';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

class MoreToursBtn extends React.Component {
    constructor(props) {
        super();

        this.state = {
            clicked:false 
        }
    }

    render() {
        return (
            <div class="padded-top-bottom flexed centered">
                <Button variant="contained"
                    className = "loadMoreToursBtn"
                    size = "large"
                    // endIcon = {<ArrowDropDownIcon/>}
                    onClick = {() => {
                        this.props.clickFunction();
                        this.setState({ clicked: !this.state.clicked });
                    }}>
                    {this.state.clicked ? "Show Less Tours" : "Load More Tours"}
                    <ArrowDropDownIcon class={this.state.clicked ? "dropdown rotate" : "dropdown"}/>
                </Button>
                {/* <div class="loadMoreToursBtn"
                    onClick={() => {
                        this.props.clickFunction();
                        this.setState({ clicked: !this.state.clicked }); 
                    }}
                    id="loadMoreToursBtn">
                    {this.state.clicked ? "Show Less Tours" : "Load More Tours"}
                </div> */}
            </div>
        );
    }
}

export default MoreToursBtn;