import React from 'react';
import TourCard from './TourCard.js'; 

class CheckboxTours extends React.Component{

    constructor(props) {
        super(); 
        this.tours = [...props.tours]; 
        this.changeFunction = props.changeFunction; 
        this.checkedTours = []; 

        this.state = {
            tourArr: [], status: false,
            bundles: "",
        }

    }

    componentDidMount(){
        if(this.props.selectedTours) this.props.selectedTours.forEach(tour => 
            {
             if(this.addTour(tour)){
                this.setState({ tourArr: this.checkedTours, status: this.checkedTours.length > 0 });
                if (this.props.purchase) {
                   this.changeFunction(this.checkedTours, this.checkedTours.length > 0, this.sumTours(), this.sumToursChild());
                }
             } 
             })
    }

    removeAllTours(){
        this.setState({
            tourArr: [], status: false, budles: ""
        }); 
        this.checkedTours = []; 
    }

    addTour(name) {
        let thisTour = this.getTour(name); 
        if(thisTour)
        {
            this.checkedTours.push(thisTour); 
            return true; 
        }
        return false; 
    }

    removeTour(name) {
        let tempArr = []
        for (let i = 0; i < this.checkedTours.length; ++i) {
            if (!(this.checkedTours[i].name === name)) {
                tempArr.push(this.checkedTours[i]); 
            }
        }
        return tempArr; 
    }

    getTour(name) {
        for (let i = 0; i < this.tours.length; ++i) {
            if(name===this.tours[i].name)return(this.tours[i])
        }
        return null; 
    }

    checkBundles(arr){
        let ret = null; 
        for(let i =0; i < this.props.bundles.length; ++i){
            if(arr.length !== this.props.bundles[i].tours.length) continue; 
            let bundleCompare = true; 
            for(let j=0;j < arr.length;++j){
                if(!this.props.bundles[i].tours.includes(arr[j])){
                    bundleCompare = false; 
                    break; 
                }
            }
            if(bundleCompare){
                return this.props.bundles[i]; 
            }
        }
        return ret; 
    }

    sumTours() {
        let ret = 0; 
        let tourNameArr = []; 
        for (let i = 0; i < this.checkedTours.length; ++i) {
            tourNameArr.push(this.checkedTours[i].name); 
            ret += this.checkedTours[i].price;
        }
        let bundleValue = this.checkBundles(tourNameArr); 
        if(bundleValue!=null) 
        {
            this.setState({bundles: bundleValue.name}); 
            this.props.bundleCallback(bundleValue.name); 
            return bundleValue.price; 
        }
        else{
            this.setState({bundles: ""}); 
        }
        return ret; 
    }

    sumToursChild() {
        let ret = 0; 
        for( let i = 0; i< this.checkedTours.length; ++i) {
            ret += this.checkedTours[i].priceChild; 
        }
        return ret; 
    }

    checkTour(event){
        event.target.checked ? this.addTour(event.target.name)
                             : this.checkedTours = this.removeTour(event.target.name);
        this.setState({ tourArr: this.checkedTours, status: this.checkedTours.length > 0 })
        if (this.props.purchase) {
            this.changeFunction(this.checkedTours, this.checkedTours.length > 0, this.sumTours(), this.sumToursChild());
        }
        else this.changeFunction(this.checkedTours, this.checkedTours.length > 0);
    }

    render() {


        return (
            <div className="contactFormField finalField"> 
                <label for="Tours">Tours (Click to Select)<span className={this.state.status ? "hidden" : "red"}>*</span></label>
                <div className="checkboxTours">
                    {
                        this.tours.map(tour => (
                                this.props.purchase && tour.name === "yacht" ? null : 
                                this.props.cruise && !tour.cruise ? null : 
                                this.props.cayes && !tour.cayes ? null : 
                                <label className="checkboxContainer" >
                            <input type="checkbox" name={tour.name} checked = {this.state.tourArr.find(o=> o === tour)}
                                onChange={(event) => {
                                    this.checkTour(event); 
                                }} />
                                < span class= "checkmark" ></span>
                                <TourCard tour = {tour} purchase = {true}/> 
                                </label >        
                        ))}
                </div>
                <ul> 
                    {this.state.bundles==="" ? null : <li>You are saving by booking the {this.state.bundles}!</li>}
                    {this.state.status ? <li>By booking this tour, you are consenting to the <a className = "prettyLink" href = "/Covid">Covid Policy</a></li>: null}
                    {this.state.tourArr.map(tour =>(
                        tour.notes == null ? null : 
                        <li>{tour.notes}</li>
                    ))}
                </ul> 
            </div> 
        );
    }

}
export default CheckboxTours; 
