import React from 'react';

import LogisticsGridItem from './LogisticsGridItem.js'; 
import BackpackIcon from '@mui/icons-material/Backpack';
import AddTaskIcon from '@mui/icons-material/AddTask';

class LogisticsGrid extends React.Component {

    constructor(props) {
        super();
    }



    render() {

        return (
            <div className="logisticsGrid">
                <LogisticsGridItem content={this.props.includes} title="This Tour Includes" icon={<AddTaskIcon/>}/>
                <LogisticsGridItem content={this.props.whatToBring} title="What to Bring" icon={<BackpackIcon/>}/>
            </div> 
        );
    }
}



export default LogisticsGrid;