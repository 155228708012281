import React from "react";

class FilterButton extends React.Component{

    constructor(props){
        super(); 
        this.state = {
            clicked: false,
            filtering: props.default
        }; 
    }

    render(){
        return(
            <div className = "filterContainer"                     
                 onClick = {(event) => {
                   let temp = this.state.clicked; 
                   this.setState({clicked: !temp});  
                 }}>
                <div className = {this.state.clicked ? "filterBy hidden" : "filterBy"}>Filter</div>
                <div className = {this.state.clicked ? "filterIcon clicked" : "filterIcon"}>
                    <div className = "filterCircle"></div>
                    <div className = "filterCircle"></div>
                    <div className = "filterCircle"></div> 
                    <div className = "filterLine"></div>
                    <div className = "filterLine"></div>
                    <div className = "filterLine"></div>
                </div>
                <div className={this.state.clicked ? "filterOptionContainer clicked" : "filterOptionContainer"}>
                    <div className="filterOptionTitle">Filter{this.state.filtering ? "ing" : ""} By: </div>
                    {this.props.options.map(option => 
                        (<div className={this.state.filtering && this.state.filtering === option ? "filterOption chosen" : "filterOption"}
                              value = {option}
                              onClick = {(event) => {
                                  this.props.onChangeFunction(event); 
                                  this.setState({filtering : option});  
                              }}>{option}</div>))}
                </div>
            </div>
        )
    }
}
export default FilterButton; 

/* Originally this was implemented using a select, option with the code below, but I didn't want to have
 * to create any complex js code to compute when the select should show options when you can just have 
 * divs set the state on click instead, and do it all manually. 
 * Should we want to re-implement a select here, the below code will work nicely. 
*/ 

/*
                <select className = {this.state.clicked ? "filterButton clicked" : "filterButton"}
                        onChange = {(event) => {
                            this.props.onChangeFunction(event);}}>
                    {this.props.options.map(option => 
                        (<option>{option}</option>))}
                </select>
*/ 