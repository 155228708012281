import React from 'react';              //Use of class-based react components

import DropdownContents from './DropdownContents.js'; 

class DropdownArea extends React.Component {
    constructor(props) {
        super();

        this.state = {
            mainToggle: false,
        }

        this.mainDropdownClick = this.mainDropdownClick.bind(this); 
    }

    mainDropdownClick() {
        this.setState({ mainToggle: !this.state.mainToggle }); 
    }

    render() {
        return (
            <div className="hamburgerContainer"> 
                <div className={this.state.mainToggle ? "hamburger toggled" : "hamburger"}
                    onClick={() => { this.mainDropdownClick() }}> 
                    <div className="left"></div>
                    <div className="mid"></div>
                    <div className="right"></div>
                 </div> 
                <DropdownContents siteUrls={this.props.siteUrls}
                                  tours={this.props.tours}
                                  show={this.state.mainToggle}/>
            </div>
        );
    }
}

export default DropdownArea;