import React from "react";
import TripadvisorWidget from "./TripadvisorWidget";

function Flex () {
    return (
        <div className="blackText flexed col">
            <h2>Celebrating 9 Years of Excellence</h2>
            <p>Major Tom's Cave Tubing tour group is humbled and grateful to receive TripAdvisor Certificates of Excellence for nine consecutive years. We take great pride in offering exceptional experiences and top-notch service to all of our customers.</p>
            <p>This recognition is a testament to our commitment to providing high-quality and memorable tours, and we could not have achieved it without the hard work and dedication of our entire team.</p>
            <p>We are honored to have received positive reviews from our customers, and we will continue to strive for excellence in all that we do. Thank you to everyone who has supported us over the years, and we look forward to continuing to provide unforgettable adventures for many more years to come.</p>
            <div className="flexed padded-top flex-wrap">
                <TripadvisorWidget type="1"/>
                <TripadvisorWidget type="2"/>
                <TripadvisorWidget type="3"/>
                <TripadvisorWidget type="4"/>
                <TripadvisorWidget type="5"/>
                <TripadvisorWidget type="6"/>
                <TripadvisorWidget type="7"/>
                <TripadvisorWidget type="8"/>
                <TripadvisorWidget type="9"/>
                <TripadvisorWidget type="10"/>
            </div>
        </div>
    );
} export default Flex;