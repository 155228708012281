import React from 'react';

import '../Styles/Tour.css'; 
import BookNow from './BookNow.js'; 
import BundleTours from './BundleTours.js'; 
import LongDescription from './LongDescription.js'; 
import LogisticsGrid from './LogisticsGrid.js'; 
import MoreToursBtn from './MoreToursBtn.js'; 
import PricingBox from './PricingBox.js'; 
import ImageSlideDeck from './ImageSlideDeck.js'; 
import TourGrid from './TourGrid.js';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class TourBody extends React.Component {

    constructor(props) {
        super();
        //Sort all tours alphabetically but with preference to always show caveTubing first. 
        this.allTours = props.tours.sort((a, b) => {return a.name === "caveTubing" ? -1 : b.name === "caveTubing" ? 1 : a.name === b.name ? 0 : a.name < b.name ? -1 : 1 });
        //Remove bundles that this specific tour isn't included in
        this.bundles = props.bundles.filter(bundle => bundle.tours.includes(props.tourInfo.name));
        //Sort the bundle tour cards to prioritize this tour name coming first, the rest alphabetically
        this.bundles.map(bundle => bundle.tours.sort((a, b) => {return a === props.tourInfo.name ? -1 :
                                                                       b === props.tourInfo.name ? 1 :
                                                                       a < b ? -1 :
                                                                       a > b ? 1:
                                                                       0}));
        this.state = {
            tours: this.allTours.filter(tour => !(tour.name === props.tourInfo.name)).slice(0, 3),
            bookingToggled: false,
            bookingBundle: null
        }
        this.toggleBooking = this.toggleBooking.bind(this); 
        this.addTours = this.addTours.bind(this); 
        this.bundleText = ["Extend your tour adventure and get fantastic savings by adding on some additional tours! Your professional guides will take you to multiple locations in the day so that you can make the most out of your time here.",
"Be sure to filter on where you are residing so that we can make sure you have as much time as possible to see the wonders of Belize."
]; 
    }

    toggleBooking(bundle){
        let temp = this.state.bookingToggled; 
        this.setState({bookingToggled: !temp});
        this.setState({bookingBundle: bundle}); 
        // if(!temp)
        //     window.scrollTo(0, 0); 
    }

    addTours() {
        if (this.state.tours === this.allTours) this.setState({ tours: this.allTours.slice(0, 3) })
        else this.setState({ tours: this.allTours });
    }

    render() {
        return (
            <div className = {!this.state.bookingToggled ? "homeContainer" : "homeContainer toggled"}>
                {/* {this.state.bookingToggled ?
                    
                    <BookNow selectedTours = {this.state.bookingBundle} closeFunction = {this.toggleBooking} iframe = {true} bundles = {this.props.bundles} tours = {this.props.tours}/> : null}  */}
                <Dialog open = {this.state.bookingToggled}
                                    scroll = {'paper'}
                                    maxWidth = {'xl'}
                                    fullWidth = {true}>
                    {/* <DialogActions>
                        <Button onClick={()=>{this.setState({bookingToggled: false})}}>Close</Button>
                    </DialogActions> */}
                    <DialogTitle className = "flexed row apart">
                        <div>
                            Reserve Your Adventure
                        </div>
                        <Button onClick={()=>{this.setState({bookingToggled: false})}}>Close</Button>
                    </DialogTitle>
                    <DialogContent className = "bookingContent">
                        <BookNow initialEmbarkment = {this.props.embarkment}
                        selectedTours = {this.state.bookingBundle}
                        closeFunction = {() => {this.toggleBooking();}}
                        iframe = {true} bundles = {this.props.bundles}
                        tours = {this.props.tours}/>
                    </DialogContent>
                </Dialog>

                <ImageSlideDeck images={this.props.tourInfo.slideImages} />
                <div className="tourBody flexed col">
                    <h1>{this.props.tourInfo.text}</h1>
                    <LongDescription content={this.props.tourInfo.content} />
                    <LogisticsGrid includes={this.props.tourInfo.includes} whatToBring={this.props.tourInfo.whatToBring}/> 
                    <PricingBox bookTourFunction = {this.toggleBooking} name = {this.props.tourInfo.name} cruise={this.props.tourInfo.cruise} price={this.props.tourInfo.price} priceChild={this.props.tourInfo.priceChild}/> 
                    {this.bundles.length > 0 ? 
                        <BundleTours tours = {this.allTours}
                                    clickBundleFunction = {this.toggleBooking}
                                    bundles = {this.bundles}
                                    bundleText = {this.bundleText}
                                    bundleTitle = "Save on Bundles Available with this Tour"/>
                    : null}
                    <h3 className="otherToursTitle">Other Popular Tours</h3> 
                    <TourGrid tours={this.state.tours}
                            bookTourFunction = {this.toggleBooking}
                            omit={this.props.tourInfo.name} /> 
                    <MoreToursBtn clickFunction={this.addTours} />
                </div>
            </div> 
            );
    }
}

export default TourBody;